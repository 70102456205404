import { gql, useLazyQuery } from "@apollo/client";
import {
  DraftStudentInput,
  GradeLevel,
  NewStudentFieldStudentQuery,
  NewStudentFields_CohortFragment,
} from "@generated/graphql";
import { getInputStyle } from "@utils/styleStrings";
import { Button, ErrorBox, Icon, Input, disabledDay } from "components/shared";
import { DatePickerInput } from "components/shared/DatePickerInput";
import { GradeLevelSelectMenu } from "components/shared/Inputs/SelectMenuOptions/GradeLevelSelectMenu";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { LabelCheckbox } from "./LabelCheckbox";

const SEARCH_ORGANIZATION_STUDENTS_QUERY = gql`
  query NewStudentFieldStudent($externalId: ID!, $organizationId: ID!) {
    studentByExternalId(
      externalId: $externalId
      organizationId: $organizationId
    ) {
      externalId
    }
  }
`;

NewStudentFields.fragments = {
  cohort: gql`
    fragment NewStudentFields_Cohort on Cohort {
      id
      endDate
      startDate
    }
  `,
};

type Props = {
  organizationId: string;
  cohort: NewStudentFields_CohortFragment | null;
  addStudent: (
    student: DraftStudentInput,
    startDate: Date | null | undefined
  ) => void;
};

export function NewStudentFields({
  cohort,
  organizationId,
  addStudent,
}: Props) {
  const [errorMsg, setErrorMsg] = useState("");
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [startDateEnabled, setStartDateEnabled] = useState(false);

  const {
    watch,
    reset,
    register,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<DraftStudentInput>({ mode: "onSubmit" });

  const [checkExternalIdUniqueness] = useLazyQuery<NewStudentFieldStudentQuery>(
    SEARCH_ORGANIZATION_STUDENTS_QUERY
  );

  const onAddStudent: SubmitHandler<DraftStudentInput> = async ({
    classroomTeacherName,
    school,
    externalId,
    fullName,
    grade,
  }: DraftStudentInput) => {
    const response = await checkExternalIdUniqueness({
      variables: { organizationId, externalId },
    });

    if (response?.data?.studentByExternalId)
      return setErrorMsg(
        "This external ID already exists in the organization. Try another."
      );

    addStudent(
      {
        grade,
        school,
        fullName,
        externalId,
        organizationId,
        classroomTeacherName,
      },
      startDate
    );

    reset();
    setValue("grade", GradeLevel.Unknown);
  };

  const externalId = watch("externalId");
  const fullName = watch("fullName");
  const grade = watch("grade");

  const isValidInput = useMemo(() => {
    return !!(externalId && fullName && grade && grade !== GradeLevel.Unknown);
  }, [externalId, fullName, grade]);

  return (
    <form
      className="flex flex-col gap-y-2"
      onSubmit={handleSubmit((e) => onAddStudent(e))}
    >
      <div className="flex gap-2">
        <Input
          id="new-student-external-id"
          className="w-[25%]"
          label="External ID"
          {...register("externalId", {
            required: {
              value: true,
              message: "External ID is required.",
            },
          })}
          required
          onChange={(e) => {
            setErrorMsg("");
            clearErrors("externalId");
            setValue("externalId", e.target.value);
          }}
          error={errors.externalId?.message}
        />

        <Input
          id="new-student-full-name"
          label="Full Name"
          className="w-[55%]"
          {...register("fullName", {
            required: {
              value: true,
              message: "You must provide a full name.",
            },
          })}
          required
          onChange={(e) => {
            clearErrors(["fullName"]);
            setValue("fullName", e.target.value);
          }}
          error={errors.fullName?.message}
        />

        <GradeLevelSelectMenu
          studentGrade
          value={grade}
          className="w-[20%]"
          onSelect={(gradeLevel) => {
            gradeLevel && setValue("grade", gradeLevel);
          }}
        />
      </div>

      <div className="flex gap-2 items-end">
        <Input
          className="w-[30%]"
          label="Classroom Teacher Name"
          id="new-student-classroom-teacher-name"
          error={errors.fullName?.message}
          {...register("classroomTeacherName", {})}
          onChange={(e) => setValue("classroomTeacherName", e.target.value)}
        />

        <Input
          className="w-[30%]"
          label="School Name"
          id="new-student-school"
          error={errors.fullName?.message}
          {...register("school", {})}
          onChange={(e) => setValue("school", e.target.value)}
        />

        <DatePickerInput
          label="Start Date"
          labelChildren={
            <LabelCheckbox
              enabled={startDateEnabled}
              setEnabled={setStartDateEnabled}
              tooltip="If student is added after cohort sessions have started
                          set the Start Date to the student's first day of class. This
                          will trigger attendance to accurately populate."
            />
          }
          className="w-[20%]"
          onChange={(date) => setStartDate(date?.toDate())}
          disabledDate={(date) =>
            disabledDay(
              date,
              cohort?.startDate,
              cohort?.endDate,
              undefined,
              undefined,
              true
            )
          }
          style={{ ...getInputStyle }}
          disabled={!startDateEnabled}
          value={dayjs(startDate)}
          showNow={false}
        />

        <Button
          type="submit"
          theme="primary"
          className="w-[20%]"
          disabled={!isValidInput}
        >
          <Icon icon="plus" color="text-white" size={4} />
        </Button>
      </div>
      <ErrorBox msg={errorMsg} />
    </form>
  );
}
