import { Modal } from "components/shared";
import { useRef } from "react";
import { AddStudentsModalBody } from "./components/AddStudentsModalBody";

type Props = {
  show: boolean;
  cohortId: string;
  cohortName: string;
  organizationId: string;
  removedCohortStudentsIds?: string[];
  closeModal: () => void;
};

export const AddStudentsModal = ({
  show,
  cohortId,
  cohortName,
  organizationId,
  removedCohortStudentsIds,
  closeModal,
}: Props) => {
  const modalRef = useRef<HTMLDivElement>(null);

  return (
    <Modal
      width="xxlarge"
      show={show}
      onClose={closeModal}
      initialFocus={modalRef}
      icon={<Modal.Icon icon="student" />}
      subtitle={`Either add existing students from this engagement or new students.`}
      title={`Add Students to Cohort ${cohortName}`}
      dataTest="add-students-modal"
    >
      <AddStudentsModalBody
        cohortId={cohortId}
        closeModal={closeModal}
        organizationId={organizationId}
        removedCohortStudentsIds={removedCohortStudentsIds}
      />
    </Modal>
  );
};
