import { VideoProvider } from "@generated/graphql";
import { assertUnreachable } from "./types";

export const getVideoProviderDisplayText = (
  videoProvider = VideoProvider.Zoom
) => {
  switch (videoProvider) {
    case VideoProvider.MicrosoftTeams:
      return "Microsoft Teams";
    case VideoProvider.Zoom:
      return "Zoom";
    case VideoProvider.GoogleMeets:
      return "Google Meets";
    case VideoProvider.Manual:
      return "Manual";
    default:
      assertUnreachable(videoProvider);
  }
};
