import { gql } from "@apollo/client";
import {
  AccountStatus,
  CohortDetailsTab_CohortFragment,
  GetMeetingDetails_CohortFragment,
  GetPdCurriculumCoursesQuery,
  User,
  VideoProvider,
} from "@generated/graphql";
import { getVideoProviderDisplayText } from "@utils/meetings";
import { Routes } from "@utils/routes";
import { assertUnreachable } from "@utils/types";
import { EngagementStaffMap } from "@utils/withFragments/staffing";
import { RoomMeetingLinks } from "components/meetings/RoomMeetingLink";
import {
  CohortAssignmentSubjectBadge,
  EngagementAssignmentRoleBadge,
  Icon,
  Link,
  Tooltip,
} from "components/shared";
import { CardDetailsRow } from "components/shared/Card";
import { CurriculumCourseOptions } from "../types";

getMeetingDetails.fragments = {
  meetingCohort: gql`
    fragment GetMeetingDetails_Cohort on Cohort {
      meetingRoom
      engagement {
        videoProvider
      }
      ...RoomLink_Cohort
    }
    ${RoomMeetingLinks.fragments.cohort}
  `,
};

export function getMeetingDetails(
  cohort: GetMeetingDetails_CohortFragment
): CardDetailsRow[] {
  const {
    meetingRoom,
    engagement: { videoProvider },
  } = cohort;

  const provider: CardDetailsRow = [
    "Video Provider",
    `${getVideoProviderDisplayText(videoProvider)}`,
  ];

  switch (videoProvider) {
    case VideoProvider.MicrosoftTeams:
    case VideoProvider.GoogleMeets:
    case VideoProvider.Zoom: {
      return [
        provider,
        [
          "Meeting Links",
          meetingRoom ? (
            <div className="flex flex-col w-fit space-y-1">
              <RoomMeetingLinks
                cohort={cohort}
                videoProvider={videoProvider}
                copyButton
                qrCode
              />
            </div>
          ) : (
            <Tooltip
              className="flex items-center"
              content={
                <div className="w-[200px] text-center gap-x-1">
                  Contact technical support if the meeting is not available
                  within 3 minutes of cohort creation
                </div>
              }
            >
              Meeting has not been created yet.
              <Icon icon="infoCircle" size={4} className="pb-1" />
            </Tooltip>
          ),
        ],
      ];
    }
    case VideoProvider.Manual:
      return [provider];
    default:
      assertUnreachable(videoProvider as never);
  }
}

export const getStaffRows = (
  staffAssignments: CohortDetailsTab_CohortFragment["staffAssignments"],
  engagementStaffMap: EngagementStaffMap,
  eventAssigneeIds: User["id"][]
): CardDetailsRow[] =>
  staffAssignments
    .filter(
      ({ user: { accountStatus } }) => accountStatus !== AccountStatus.Inactive
    )
    .map(({ cohortSubject, user }, i) => [
      <div key={i} className="flex gap-2">
        <Link route={Routes.user.details} routeProps={[user.id]}>
          {user.fullName}
        </Link>
        {!eventAssigneeIds.includes(user.id) && (
          <span className="text-xs">(unassigned)</span>
        )}
      </div>,
      <div
        key={i}
        className="flex flex-row flex-wrap-reverse gap-2 justify-end"
      >
        {engagementStaffMap[user.id] && (
          <EngagementAssignmentRoleBadge
            role={engagementStaffMap[user.id]}
            mode="short"
          />
        )}
        <CohortAssignmentSubjectBadge cohortSubject={cohortSubject} />
      </div>,
    ]);

export const getSpan = (label: string) => (
  <span className="flex whitespace-nowrap">{label}</span>
);

export const getCourseOptions = (
  data?: GetPdCurriculumCoursesQuery
): CurriculumCourseOptions =>
  data
    ? data.getCurriculumCourses.map((course) => ({
        id: course.id,
        value: course.name,
        label: course.name,
        courseId: course.courseId,
      }))
    : [];
