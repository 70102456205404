import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  RegenerateCohortMeetingMutation,
  RegenerateCohortMeetingMutationVariables,
  RoomLink_CohortFragment,
  VideoProvider,
} from "@generated/graphql";
import { PlusSmIcon, QrcodeIcon } from "@heroicons/react/outline";
import { getOrigin } from "@utils/browser";
import {
  GoogleRoomType,
  ManualRoomType,
  MicrosoftTeamsRoomType,
  RoomType,
  ZoomRoomType,
  getShortMeetingLink,
} from "@utils/roomUrls";
import { assertUnreachable } from "@utils/types";
import {
  Link,
  MinimalCopyButton,
  Modal,
  QrCodeImage,
  Tooltip,
} from "components/shared";
import {
  triggerErrorToast,
  triggerSuccessToast,
} from "components/shared/Toast";
import { useState } from "react";

const REGENERATE_COHORT_MEETING = gql`
  mutation RegenerateCohortMeeting($cohortId: ID!) {
    regenerateCohortMeeting(cohortId: $cohortId) {
      id
    }
  }
`;

RoomMeetingLink.fragments = {
  cohort: gql`
    fragment RoomLink_Cohort on Cohort {
      id
      publicReferenceId
    }
  `,
};

type NamePrefix = "none" | "short" | "full";

// Specifically structured to ensure correct combination of props.
type Providers =
  | {
      videoProvider: VideoProvider.Zoom;
      roomType: ZoomRoomType;
    }
  | {
      videoProvider: VideoProvider.MicrosoftTeams;
      roomType: MicrosoftTeamsRoomType;
    }
  | {
      videoProvider: VideoProvider.GoogleMeets;
      roomType: GoogleRoomType;
    }
  | {
      videoProvider: VideoProvider.Manual;
      roomType: ManualRoomType;
    };

type BaseProps = {
  cohort: RoomLink_CohortFragment;
  namePrefix?: NamePrefix;
  copyButton?: boolean;
  qrCode?: boolean;
};

type Props = BaseProps & Providers;

export function RoomMeetingLink({
  cohort: { publicReferenceId, id },
  roomType,
  videoProvider,
  namePrefix = "none",
  copyButton = false,
  qrCode = false,
}: Props) {
  const [showRegenerateMeetingModal, setShowRegenerateMeetingModal] =
    useState(false);

  const [regenerateCohortMeeting, { loading }] = useMutation<
    RegenerateCohortMeetingMutation,
    RegenerateCohortMeetingMutationVariables
  >(REGENERATE_COHORT_MEETING, {
    onError: (error: ApolloError) =>
      triggerErrorToast({
        message: "Failed to regenerate meeting.",
        sub: error.message,
      }),
    onCompleted: () => {
      triggerSuccessToast({
        message: "Meeting will regenerate within 3 minutes!",
      });
      setShowRegenerateMeetingModal(false);
    },
  });

  const roomShortURL = getShortMeetingLink(
    getOrigin(),
    publicReferenceId,
    roomType
  );

  const regenerateMeeting = () =>
    regenerateCohortMeeting({
      variables: {
        cohortId: id,
      },
      refetchQueries: ["CohortDetailsPage"],
    });

  return (
    <div className="flex items-center gap-2">
      <Link href={roomShortURL} target="_blank">
        {getDisplayText(roomType, videoProvider, namePrefix)}
      </Link>
      {copyButton && <MinimalCopyButton copyValue={roomShortURL} />}

      {qrCode && (
        <Tooltip
          content={<QrCodeImage text={roomShortURL} size={125} margin={2} />}
          tooltipProps={{
            clickable: true,
            events: ["click"],
            opacity: 1,
            style: { backgroundColor: "#eee", color: "#222" },
          }}
        >
          <button
            className="flex flex-center"
            data-bs-toggle="tooltip"
            title="Show QR Code"
          >
            <QrcodeIcon className="h-5 w-5 text-gray-500" />
            <span className="ml-1 text-xs text-gray-500">QR Code</span>
          </button>
        </Tooltip>
      )}
      {videoProvider == VideoProvider.MicrosoftTeams && (
        <button
          className="flex flex-center"
          data-bs-toggle="tooltip"
          title="Regenerate Meeting"
          onClick={() => {
            setShowRegenerateMeetingModal(true);
          }}
        >
          <PlusSmIcon className="h-5 w-5 text-gray-500" />
          <span className="ml-1 text-xs text-gray-500">Regenerate Meeting</span>
        </button>
      )}
      <Modal
        show={showRegenerateMeetingModal}
        onClose={() => {
          setShowRegenerateMeetingModal(false);
        }}
        onDismissClick={() => {
          setShowRegenerateMeetingModal(false);
        }}
        title="Regenerate Meeting"
      >
        <div className="p-4">
          <p className="text-sm">
            If you are not seeing accurate live participants for this cohort,
            regenerating the meeting may help. Make sure no one is in the
            meeting before regenerating.
          </p>
        </div>

        <Modal.Buttons>
          <Modal.Button
            type="delete"
            onClick={() => {
              regenerateMeeting();
            }}
            loading={loading}
          >
            Confirm
          </Modal.Button>
          <Modal.Button
            type="cancel"
            onClick={() => {
              setShowRegenerateMeetingModal(false);
            }}
          >
            Cancel
          </Modal.Button>
        </Modal.Buttons>
      </Modal>
    </div>
  );
}

function getDisplayText(
  roomType: RoomType,
  videoProvider: VideoProvider,
  namePrefix: NamePrefix
) {
  let nameText = "";

  if (namePrefix !== "none") {
    switch (videoProvider) {
      case VideoProvider.Zoom:
        nameText = "Zoom";
        break;
      case VideoProvider.MicrosoftTeams:
        nameText = namePrefix === "full" ? "Microsoft Teams" : "Teams";
        break;
      case VideoProvider.GoogleMeets:
        nameText = "Google Meets";
        break;
      case VideoProvider.Manual:
        nameText = "Manual";
        break;
      default:
        assertUnreachable(videoProvider);
    }
    nameText += " ";
  }

  switch (roomType) {
    case "all":
      return nameText + "Meeting Link";
    case "backdoor":
      return nameText + "Backdoor Link";
    case "host":
      return nameText + "Host Link";
    case "student":
      return nameText + "Student Link";
    default:
      assertUnreachable(roomType);
  }
}

RoomMeetingLinks.fragments = RoomMeetingLink.fragments;
type RoomMeetingLinksProps = BaseProps & { videoProvider: VideoProvider };

/**
 * Convenience component simply returns all the links you need for a particular
 * video provider. Multiple links are in a Fragment, so make sure you display
 * them correctly.
 *
 * @param props
 * @returns
 */
export function RoomMeetingLinks(props: RoomMeetingLinksProps) {
  const { videoProvider } = props;

  switch (videoProvider) {
    case VideoProvider.Zoom:
      return (
        <>
          <RoomMeetingLink
            {...props}
            videoProvider={VideoProvider.Zoom}
            roomType="host"
          />
          <RoomMeetingLink
            {...props}
            videoProvider={VideoProvider.Zoom}
            roomType="student"
          />
        </>
      );

    case VideoProvider.MicrosoftTeams:
      return (
        <RoomMeetingLink
          {...props}
          videoProvider={VideoProvider.MicrosoftTeams}
          roomType="all"
        />
      );
    case VideoProvider.GoogleMeets:
      return (
        <RoomMeetingLink
          {...props}
          videoProvider={VideoProvider.GoogleMeets}
          roomType="all"
        />
      );
    case VideoProvider.Manual:
      return (
        <RoomMeetingLink
          {...props}
          videoProvider={VideoProvider.Manual}
          roomType="all"
        />
      );
    default:
      assertUnreachable(videoProvider);
  }
}
