import { CohortStudentTableRow } from "./CohortStudentsTable";
export const sortCohortStudents = (
  a: CohortStudentTableRow,
  b: CohortStudentTableRow
): number => {
  if (a.cannotRemove !== true && b.cannotRemove === true) return -1;
  if (a.cannotRemove === true && b.cannotRemove !== true) return 1;

  if (a.isRemoved && !b.isRemoved) return 1;
  if (!a.isRemoved && b.isRemoved) return -1;

  if (a.isLateAddition && !b.isLateAddition) return 1;
  if (!a.isLateAddition && b.isLateAddition) return -1;

  return (a.student?.fullName ?? "Unknown Student").localeCompare(
    b.student?.fullName ?? "Unknown Student"
  );
};
