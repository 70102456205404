import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  DeleteCohortStudentMutation,
  DeleteCohortStudentMutationVariables,
  RemoveCohortStudentModalBody_CohortStudentFragment,
} from "@generated/graphql";
import { normalizeToUtcDate } from "@utils/dateTime";
import { getInputStyle } from "@utils/styleStrings";
import { DatePicker } from "antd";
import {
  COHORT_DETAILS_PAGE_QUERY_NAME,
  STUDENT_DETAILS_PAGE_QUERY_NAME,
} from "components/cohorts/constants";
import {
  Details,
  ErrorBox,
  Modal,
  triggerErrorToast,
  triggerSuccessToast,
} from "components/shared";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

const DELETE_COHORT_STUDENT_RELATIONS_MUTATION = gql`
  mutation DeleteCohortStudent($input: DeleteCohortStudentInput!) {
    deleteCohortStudent(input: $input)
  }
`;

RemoveCohortStudentModalBody.fragments = {
  cohortStudent: gql`
    fragment RemoveCohortStudentModalBody_CohortStudent on CohortStudent {
      id
      student {
        fullName
        externalId
      }
    }
  `,
};

type Props = {
  cohortName: string;
  cohortStartDate: Date;
  cohortEndDate: Date;
  cohortStudent: RemoveCohortStudentModalBody_CohortStudentFragment;
  onCancel: () => void;
  onSuccess: () => void;
};

export function RemoveCohortStudentModalBody({
  cohortName,
  cohortStartDate,
  cohortEndDate,
  cohortStudent: {
    id: cohortStudentId,
    student: { fullName, externalId },
  },
  onSuccess,
  onCancel,
}: Props) {
  const [removedAtDate, setRemovedAtDate] = useState<Date>(new Date());

  const [removalNotes, setRemovalNotes] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const [deleteCohortStudentRelations] = useMutation<
    DeleteCohortStudentMutation,
    DeleteCohortStudentMutationVariables
  >(DELETE_COHORT_STUDENT_RELATIONS_MUTATION, {
    onError: (err: ApolloError) => {
      setErrorMsg(err.message);
    },
    refetchQueries: [
      COHORT_DETAILS_PAGE_QUERY_NAME,
      STUDENT_DETAILS_PAGE_QUERY_NAME,
    ],
  });

  const onRemoveStudent = async () => {
    const { data, errors } = await deleteCohortStudentRelations({
      variables: {
        input: {
          cohortStudentId,
          removedAt: normalizeToUtcDate(removedAtDate).getTime(),
          removalNotes,
          deleteCompletely:
            new Date(removedAtDate).getTime() <
            new Date(cohortStartDate).getTime(),
        },
      },
    });

    if (data?.deleteCohortStudent !== 0 && !errorMsg) {
      triggerSuccessToast({
        message: `Removed ${fullName || "Student"} ${externalId} from Cohort!`,
      });
      onSuccess();
    } else {
      triggerErrorToast({
        message: `Failed to remove ${fullName || "Student"} ${externalId}`,
        sub: errors && errors.length > 0 ? errors?.join("; ") : undefined,
      });
    }
  };

  useEffect(() => {
    const isInvalidDate =
      new Date(removedAtDate).getTime() > new Date(cohortEndDate).getTime();
    setErrorMsg(
      isInvalidDate ? "Removed date cannot be after cohort end date." : null
    );
  }, [cohortEndDate, cohortStartDate, removedAtDate]);

  return (
    <>
      <ErrorBox msg={errorMsg} className="mb-3" />

      <Details className="w-full ml-2 flex flex-col gap-y-6">
        <Details.Line>
          <Details.Term>Student</Details.Term>
          <Details.Detail>{fullName}</Details.Detail>
        </Details.Line>
        <Details.Line>
          <Details.Term>Cohort</Details.Term>
          <Details.Detail>{cohortName}</Details.Detail>
        </Details.Line>
        <Details.Line>
          <Details.Term>Removed on</Details.Term>
          <Details.Detail>
            <DatePicker
              onChange={(date) => date && setRemovedAtDate(date.toDate())}
              defaultValue={dayjs(removedAtDate)}
              style={{ ...getInputStyle, width: "50%" }}
              showNow={false}
            />
          </Details.Detail>
        </Details.Line>

        <div className="py-2 flex items-center w-full">
          <div className="text-sm font-medium text-gray-500 mr-3">Notes</div>
          <div className="flex-grow">
            <textarea
              id="notes"
              required
              className="w-full min-h-[80px] max-h-[300px] overflow-auto border-gray-300 rounded-md text-sm font-normal"
              value={removalNotes}
              onChange={(e) => setRemovalNotes(e.target.value)}
            />
          </div>
        </div>
      </Details>

      <Modal.Buttons>
        <Modal.Button
          type="confirm"
          onClick={onRemoveStudent}
          disabled={
            new Date(removedAtDate).getTime() >
            new Date(cohortEndDate).getTime()
          }
        >
          Remove
        </Modal.Button>
        <Modal.Button type="cancel" onClick={onCancel}>
          Cancel
        </Modal.Button>
      </Modal.Buttons>
    </>
  );
}
