import { normalizeDateFromUTCDateTime } from "@utils/dateTime";
import clsx from "clsx";
import { Icon, Tooltip } from "components/shared";
import { isSameDay, isSameMonth } from "date-fns";
import dayjs from "dayjs";

export const normalize = (dates: string[] | undefined) =>
  (dates ?? []).map((d) => normalizeDateFromUTCDateTime(new Date(d)));

export const specialDaysCellRenderer = (
  current: dayjs.Dayjs,
  month: dayjs.Dayjs,
  updatedPrimarySpecialDays: Date[],
  secondarySpecialDays: Date[]
) => {
  const isToday = isSameDay(new Date(), current.toDate());
  const isPrimarySpecialDay = updatedPrimarySpecialDays.some((SpecialDate) =>
    isSameDay(new Date(SpecialDate), current.toDate())
  );

  const isSecondarySpecialDay = secondarySpecialDays.some((SpecialDate) =>
    isSameDay(new Date(SpecialDate), current.toDate())
  );

  const isSecondarySpecialFromOtherMonth =
    isSecondarySpecialDay && !isSameMonth(current.toDate(), month.toDate());

  const isPrimarySpecialFromOtherMonth =
    isPrimarySpecialDay && !isSameMonth(current.toDate(), month.toDate());

  const isPrimaryAndSecondary = isSecondarySpecialDay && isPrimarySpecialDay;

  const isPrimaryAndSecondaryOtherMonth =
    isSecondarySpecialFromOtherMonth && isPrimarySpecialFromOtherMonth;

  return (
    <div
      className={clsx(
        isSecondarySpecialDay && "rounded-xl mx-[6px] text-white bg-purple-500",
        isPrimarySpecialDay && "rounded-xl mx-[6px] text-white bg-blue-500",
        isSecondarySpecialFromOtherMonth && "bg-purple-200",
        isPrimarySpecialFromOtherMonth && "bg-blue-200",
        isToday && "rounded-xl mx-[6px] text-white bg-red-500",
        isToday &&
          isSecondarySpecialDay &&
          "bg-gradient-to-b from-red-500 to-purple-500",
        isToday &&
          isPrimarySpecialDay &&
          "bg-gradient-to-b from-red-500 to-blue-500",
        isPrimaryAndSecondary && "bg-gradient-to-b from-blue-500 to-purple-500",
        isPrimaryAndSecondaryOtherMonth &&
          "bg-gradient-to-t from-blue-300 to-purple-200",
        isToday &&
          isPrimaryAndSecondary &&
          "bg-gradient-to-b from-blue-500 via-red-500 to-purple-500"
      )}
    >
      {current.date()}
    </div>
  );
};

export const noTutoringDayTooltip = () => (
  <Tooltip
    className="flex cursor-pointer w-fit items-start gap-[6px] min-w-[142px]"
    content={
      <div className="block w-48 text-sm text-center">
        School or district specific days where tutoring is not expected to take
        place. TTs/IS are not expected to be present and are not paid for these
        days.
      </div>
    }
  >
    No Tutoring Days
    <Icon icon="info" size={4} />
  </Tooltip>
);

export const holidayTooltip = () => (
  <Tooltip
    className="flex cursor-pointer w-fit items-start gap-[6px]"
    content={
      <div className="block w-48 text-sm text-center">
        These are National holidays where tutoring is not expected to take
        place. TTs/IS are not expected to be present and are not paid for these
        days.
      </div>
    }
  >
    US Holidays
    <Icon icon="info" size={4} />
  </Tooltip>
);

export const noShowDayTooltip = (cohort: boolean) => (
  <Tooltip
    className="flex flex-col leading-none gap-y-[2px] cursor-pointer w-fit"
    content={
      <div className="block w-48 text-sm text-center">
        Student attendance for these no-show days will be ignored in attendance
        calculations. TT/IS are still paid for these days.
      </div>
    }
  >
    <div className="flex items-end justify-start gap-2">
      {cohort ? "Cohort" : "Engagement"}
      <Icon icon="info" size={4} />
    </div>
    No-Show Days
  </Tooltip>
);

export const assessmentDaysTooltip = (cohort: boolean) => (
  <Tooltip
    className="flex flex-col leading-none gap-y-[2px] cursor-pointer w-fit"
    content={
      <div className="block w-48 text-sm text-center">
        We do not expect students to attend on these days due to them taking
        assessments in person, so grading will be ignored on these days. TT/IS
        are expected to be present and are paid for these days.
      </div>
    }
  >
    <div className="flex items-end justify-start gap-[6px]">
      {cohort ? "Cohort" : "Engagement"}
      <Icon icon="info" size={4} />
    </div>
    Assessment Days
  </Tooltip>
);

export const cancellationDaysTooltip = () => (
  <Tooltip
    className="flex flex-col leading-none gap-y-[2px] cursor-pointer w-fit"
    content={
      <div className="block w-48 text-sm text-center">
        List of days with cancelled sessions.
      </div>
    }
  >
    <div className="flex justify-start items-start gap-[6px]">
      Cancelled Session Days
      <Icon icon="info" size={4} />
    </div>
  </Tooltip>
);
