import { gql } from "@apollo/client";
import {
  EditEngagementShiftRecordModal_EngagementFragment,
  EngagementShiftCellData,
} from "@generated/graphql";
import { Modal } from "components/shared";
import { noop } from "lodash";
import { useEffect, useState } from "react";
import { ShiftModalEditType } from "../../types";
import { EditEngagementShiftRecordModalBody } from "./components/EditEngagementShiftRecordModalBody";

EditEngagementShiftRecordModal.fragments = {
  engagement: gql`
    fragment EditEngagementShiftRecordModal_Engagement on Engagement {
      engagementShifts {
        id
        ...EditEngagementShiftRecordModalBody_EngagementShift
      }
      staffAssignments {
        ...EditEngagementShiftRecordModalBody_EngagementStaffAssignment
      }
    }
    ${EditEngagementShiftRecordModalBody.fragments.engagementShift}
    ${EditEngagementShiftRecordModalBody.fragments.staffAssignments}
  `,
};

type Props = {
  show: boolean;
  closeModal: () => void;
  timePeriodString?: string;
  shiftModalEditType?: ShiftModalEditType;
  engagementShiftId: string | null;
  timePeriodData?: EngagementShiftCellData[];
  engagement: NonNullable<EditEngagementShiftRecordModal_EngagementFragment>;
};

export default function EditEngagementShiftRecordModal({
  show,
  closeModal,
  engagement,
  timePeriodData,
  engagementShiftId,
  timePeriodString = "",
  shiftModalEditType = ShiftModalEditType.Single,
}: Props) {
  const [engagementShift, setEngagementShift] = useState<
    | EditEngagementShiftRecordModal_EngagementFragment["engagementShifts"][number]
    | undefined
  >(undefined);

  useEffect(() => {
    if (engagementShiftId && engagement?.engagementShifts) {
      const engagementStaffAssignment = engagement?.engagementShifts.find(
        ({ id }) => id === engagementShiftId
      );
      if (engagementStaffAssignment)
        setEngagementShift(engagementStaffAssignment);
    } else {
      setEngagementShift(undefined);
    }
  }, [engagement, engagement?.engagementShifts, engagementShiftId]);

  return (
    <Modal
      icon={<Modal.Icon icon="calendar" />}
      show={show}
      onClose={noop}
      title={getModalTitle(shiftModalEditType, timePeriodString)}
      subtitle={getModalSubtitle(shiftModalEditType, timePeriodString)}
      width="large"
    >
      <EditEngagementShiftRecordModalBody
        shiftModalEditType={shiftModalEditType}
        engagementShift={engagementShift}
        timePeriodShiftData={timePeriodData}
        onCancel={closeModal}
        onSuccess={closeModal}
        staffAssignments={engagement?.staffAssignments}
      />
    </Modal>
  );
}

const getModalTitle = (title: string, timePeriod: string) =>
  title.replace(/\${period}/g, timePeriod);

const getModalSubtitle = (
  shiftModalEditType: ShiftModalEditType,
  timePeriodString: string
): string =>
  ({
    [ShiftModalEditType.Single]:
      "Update the selected shift with the following selected teachers.",
    [ShiftModalEditType.TimeSlot]: `Update all shifts that need to be filled in the ${timePeriodString} time slot with the following selected teachers.`,
    [ShiftModalEditType.Day]: `Update all shifts that need to be filled on ${timePeriodString}s with the following selected teachers.`,
  })[shiftModalEditType] || "";
