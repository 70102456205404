import { DocumentNode, gql, useMutation, useQuery } from "@apollo/client";
import {
  CohortCoursesCard_CohortFragment,
  GetPdCurriculumCoursesQuery,
  UpdateCohortCourseLinksMutation,
  UpdateCohortCourseLinksMutationVariables,
} from "@generated/graphql";
import { fetchErrToast } from "@utils/errorLogging";
import { optionsArrayContainerItemStyle } from "@utils/styleStrings";
import { Icon, Modal } from "components/shared";
import { OptionsArrayContainer } from "components/shared/Inputs/OptionsArrayContainer";
import { partition } from "lodash";
import { useEffect, useMemo, useState } from "react";

const GET_CURRICULUM_COURSES = gql`
  query GetPDCurriculumCourses {
    getCurriculumCourses {
      id
      name
      courseId
    }
  }
`;

const UPDATE_TALENT_LMS_COURSE_LINKS = gql`
  mutation UpdateCohortCourseLinks($cohortId: ID!, $courseIds: [ID!]!) {
    updateCohortCourseLinks(cohortId: $cohortId, courseIds: $courseIds) {
      additions
      deletions
    }
  }
`;

type Props = {
  show: boolean;
  refetchQueries: DocumentNode[];
  cohort: CohortCoursesCard_CohortFragment;
  onClose: () => void;
};

export function CurriculumCourseModal({
  show,
  cohort,
  refetchQueries,
  onClose,
}: Props) {
  const { id, talentLMSCourses: requiredCourses } = cohort;
  const ids = requiredCourses.map((course) => course.courseId);
  const [selectedCourseIds, setSelectedCourseIds] = useState<string[]>(ids);

  useEffect(() => {
    if (show) setSelectedCourseIds(ids);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const { data, loading } = useQuery<GetPdCurriculumCoursesQuery>(
    GET_CURRICULUM_COURSES,
    {
      fetchPolicy: "cache-and-network",
      onError: (error) => fetchErrToast("cohort student data", error),
    }
  );

  const [editCohortPDCoursesMutation, { loading: mutationLoading }] =
    useMutation<
      UpdateCohortCourseLinksMutation,
      UpdateCohortCourseLinksMutationVariables
    >(UPDATE_TALENT_LMS_COURSE_LINKS, {
      variables: { cohortId: id, courseIds: selectedCourseIds },
      onCompleted: onClose,
      refetchQueries,
    });

  const curriculumCourses = useMemo(() => {
    const courses = (data?.getCurriculumCourses ?? []).slice();
    return courses.sort((a, b) => a.name.localeCompare(b.name));
  }, [data?.getCurriculumCourses]);

  const [selectedCourses, availableCourses] = partition(
    curriculumCourses,
    (course) => selectedCourseIds.includes(course.courseId)
  );

  return (
    <Modal
      show={show}
      width="xlarge"
      onClose={onClose}
      icon={<Modal.Icon icon="course" />}
      title="Required PD Curriculum Courses"
      subtitle="Select required courses."
    >
      <div className="flex flex-col gap-y-3 my-4">
        <OptionsArrayContainer
          label="Available Courses"
          minHeight="min-h-[60px]"
          hasItems={availableCourses.length > 0}
          noItemsText="All courses are required."
          items={availableCourses.map((course, index) => (
            <span
              key={index}
              className={optionsArrayContainerItemStyle("add")}
              onClick={() =>
                setSelectedCourseIds([...selectedCourseIds, course.courseId])
              }
            >
              {course.name}
              <Icon icon="add" size={5} color="text-blue-500" />
            </span>
          ))}
        />
        <OptionsArrayContainer
          label="Required Courses"
          minHeight="min-h-[60px]"
          hasItems={selectedCourses.length > 0}
          noItemsText="No courses are currently set to be required"
          items={selectedCourses.map((course, index) => (
            <span
              key={index}
              className={optionsArrayContainerItemStyle("remove")}
              onClick={() =>
                setSelectedCourseIds(
                  selectedCourseIds.filter((id) => id !== course.courseId)
                )
              }
            >
              {course.name}
              <Icon icon="remove" size={5} color="text-rose-500" />
            </span>
          ))}
        />
      </div>

      <Modal.Buttons>
        <Modal.Button
          type="confirm"
          onClick={editCohortPDCoursesMutation}
          loading={loading || mutationLoading}
        >
          Confirm
        </Modal.Button>
        <Modal.Button type="cancel" onClick={onClose}>
          Cancel
        </Modal.Button>
      </Modal.Buttons>
    </Modal>
  );
}
