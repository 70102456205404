import { gql } from "@apollo/client";
import { RemoveCohortStudentModal_CohortStudentFragment } from "@generated/graphql";
import { EmptyStateContainer, Icon, Modal } from "components/shared";
import { noop } from "lodash";
import { RemoveCohortStudentModalBody } from "./RemoveCohortStudentModalBody";

RemoveCohortStudentModal.fragments = {
  cohortStudent: gql`
    fragment RemoveCohortStudentModal_CohortStudent on CohortStudent {
      ...RemoveCohortStudentModalBody_CohortStudent
    }
    ${RemoveCohortStudentModalBody.fragments.cohortStudent}
  `,
};

type Props = {
  show: boolean;
  closeModal: () => void;
  cohortStudent?: RemoveCohortStudentModal_CohortStudentFragment | null;
  cohortName: string;
  cohortStartDate: Date;
  cohortEndDate: Date;
};

export function RemoveCohortStudentModal({
  show,
  closeModal,
  cohortName,
  cohortStartDate,
  cohortStudent,
  cohortEndDate,
}: Props) {
  return (
    <Modal
      show={show}
      onClose={noop}
      icon={<Modal.Icon icon="removeUser" type="danger" />}
      title={"Remove Student from Cohort"}
    >
      <div className="flex flex-col w-full mt-3">
        {cohortStudent ? (
          <RemoveCohortStudentModalBody
            onCancel={() => closeModal()}
            onSuccess={() => closeModal()}
            cohortStudent={cohortStudent}
            cohortName={cohortName}
            cohortStartDate={cohortStartDate}
            cohortEndDate={cohortEndDate}
          />
        ) : (
          <EmptyStateContainer
            dataName="Cohort Student"
            icon={<Icon icon="student" size={8} />}
            subtitle="No cohort student selected"
          />
        )}
      </div>
    </Modal>
  );
}
