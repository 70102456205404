import { gql, useQuery } from "@apollo/client";
import {
  DraftCohortStudentInput,
  DraftStudentInput,
  SearchOrganizationStudentsQuery,
  StudentFilter,
  StudentSearchBox_CohortFragment,
} from "@generated/graphql";
import { fetchErrToast } from "@utils/errorLogging";
import { getInputStyle } from "@utils/styleStrings";
import {
  buildTextQueryFilterFunc,
  filterCombiner,
} from "@utils/useAdvancedSearch";
import {
  AdvancedSearchTooltip,
  Button,
  disabledDay,
  Icon,
} from "components/shared";
import { DatePickerInput } from "components/shared/DatePickerInput";
import { SearchDropdown, SearchSelectOption } from "components/shared/Inputs";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useDebounce } from "use-debounce";
import { LabelCheckbox } from "./LabelCheckbox";

const SEARCH_LIMIT = 10;

const textQueryFilterFunc = buildTextQueryFilterFunc<
  StudentFilter,
  keyof StudentFilter
>(["fullName", "externalId"]);

const SEARCH_ORGANIZATION_STUDENTS_QUERY = gql`
  query SearchOrganizationStudents($filter: StudentFilter!, $limit: Int) {
    advancedSearchStudents(filter: $filter, limit: $limit) {
      items {
        id
        grade
        school
        fullName
        externalId
        classroomTeacherName
      }
    }
  }
`;

StudentSearchBox.fragments = {
  cohort: gql`
    fragment StudentSearchBox_Cohort on Cohort {
      id
      startDate
      endDate
    }
  `,
};

type Props = {
  organizationId: string;
  savedStudentIds: string[];
  currentStudents: DraftCohortStudentInput[];
  cohort: StudentSearchBox_CohortFragment | null;
  addStudent: (
    student: DraftStudentInput,
    startDate: Date | null | undefined
  ) => void;
};

export function StudentSearchBox({
  cohort,
  organizationId,
  savedStudentIds,
  currentStudents,
  addStudent,
}: Props) {
  const [query, setQuery] = useState("");
  const [debouncedQuery] = useDebounce(query, 600);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [startDateEnabled, setStartDateEnabled] = useState(false);
  const [student, setStudent] = useState<DraftStudentInput | null>(null);

  const { data, refetch, loading } = useQuery<SearchOrganizationStudentsQuery>(
    SEARCH_ORGANIZATION_STUDENTS_QUERY,
    {
      fetchPolicy: "network-only",
      variables: {
        filter: filterCombiner(
          {
            organizationId: { equals: parseInt(organizationId) },
            externalId: {
              notIn: currentStudents.map(({ student }) => student.externalId),
            },
          } as StudentFilter,
          textQueryFilterFunc(debouncedQuery)
        ),
        limit: SEARCH_LIMIT,
      },
      onError: (error) => fetchErrToast("student list.", error),
    }
  );

  useEffect(() => {
    refetch();
  }, [debouncedQuery, refetch]);

  const addSelectedStudent = () => {
    if (!student) return;
    addStudent(student, startDate);
    setStudent(null);
  };

  const onSelect = (option: SearchSelectOption | null) => {
    if (!option) return setStudent(null);
    const externalId = (option as SearchSelectOption).value;
    const students = data?.advancedSearchStudents.items ?? [];
    const selectedStudent = students.find((s) => s.externalId === externalId);
    if (!selectedStudent) return setStudent(null);
    setStudent({ organizationId, ...selectedStudent });
  };

  const options: SearchSelectOption[] = useMemo(() => {
    if (!data?.advancedSearchStudents.items) return [];
    const students = data?.advancedSearchStudents.items ?? [];

    return students
      .filter(({ id }) => !savedStudentIds.includes(id))
      .map(({ externalId, fullName }) => ({
        value: externalId,
        label: fullName ?? `No name: ${externalId}`,
      }));
  }, [data?.advancedSearchStudents.items, savedStudentIds]);

  const selectedOption = useMemo(
    () => options.find(({ value }) => value === student?.externalId) ?? null,
    [options, student?.externalId]
  );

  return (
    <form
      className="flex flex-col w-full justify-center h-full items-center"
      onSubmit={(e) => {
        e.preventDefault();
        addSelectedStudent();
      }}
    >
      <div className="flex items-end w-full gap-x-2">
        <SearchDropdown
          name={"search-students-dropdown"}
          placeholder={"Students in Organization"}
          options={options}
          onSelect={onSelect}
          onInputChange={setQuery}
          onEnterPress={addSelectedStudent}
          selection={selectedOption}
          className="flex flex-1"
          isLoading={loading}
          label={
            <div className="flex items-center">
              Student ID or Name
              <AdvancedSearchTooltip>
                <p>Search by Student external ID or partial name.</p>
                <p>Case-insensitive.</p>
              </AdvancedSearchTooltip>
            </div>
          }
        />

        <DatePickerInput
          label="Start Date"
          className="w-[200px]"
          value={dayjs(startDate)}
          style={{ ...getInputStyle }}
          disabled={!startDateEnabled}
          labelChildren={
            <LabelCheckbox
              enabled={startDateEnabled}
              setEnabled={setStartDateEnabled}
              tooltip="If student is added after cohort sessions have started
                        set the Start Date to students first day of class. This
                        will trigger attendance to accurately populate."
            />
          }
          onChange={(date) => setStartDate(date?.toDate())}
          disabledDate={(date) =>
            disabledDay(
              date,
              cohort?.startDate,
              cohort?.endDate,
              undefined,
              undefined,
              true
            )
          }
        />

        <Button
          type="submit"
          theme="primary"
          className="w-[20%]"
          onClick={addSelectedStudent}
        >
          <Icon icon="plus" color="text-white" size={4} />
        </Button>
      </div>
    </form>
  );
}
