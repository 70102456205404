import { gql } from "@apollo/client";
import { CohortMeetingsCard_CohortFragment } from "@generated/graphql";
import { CohortsScheduleCalendarModal } from "components/cohorts/CohortsScheduleCalendar";
import { ICalendarGeneratorPopover } from "components/iCalendar/ICalendarGeneratorPopover";
import { Button, Icon } from "components/shared";
import { Card, HeaderColors, HeightMode } from "components/shared/Card";
import { useState } from "react";
import { getMeetingDetails } from "./helpers";

CohortMeetingsCard.fragments = {
  cohort: gql`
    fragment CohortMeetingsCard_Cohort on Cohort {
      id
      ...GetMeetingDetails_Cohort
    }
    ${getMeetingDetails.fragments.meetingCohort}
  `,
};

type Props = {
  className?: string;
  cohort: CohortMeetingsCard_CohortFragment;
};

export function CohortMeetingsCard({ className, cohort }: Props) {
  const [showScheduleModal, setShowScheduleModal] = useState(false);

  return (
    <>
      <Card
        icon="video"
        header="Meetings"
        className={className}
        heightMode={HeightMode.Fit}
        headerOverlayColor={HeaderColors.Teal}
        rows={[
          ...getMeetingDetails(cohort),
          [
            "Schedule",
            <div
              key={0}
              className="flex flex-row items-center gap-x-1 gap-y-2 text-gray-900"
            >
              <Button
                height="xs"
                theme="tertiary"
                className="flex items-center text-sm font-medium shadow-none border border-gray-300"
                onClick={() => setShowScheduleModal(true)}
              >
                <Icon
                  icon="calendarEvents"
                  color="text-slate-600"
                  size={4}
                  button
                />
                Show
              </Button>
              <ICalendarGeneratorPopover
                buttonHeight="xs"
                openOrientation="BOTTOM"
                buttonClassName="text-base font-medium shadow-none border border-gray-300"
                initialBuildICalendarInput={{ cohortIds: [cohort.id] }}
              />
            </div>,
          ],
        ]}
      />

      <CohortsScheduleCalendarModal
        show={showScheduleModal}
        closeModal={() => setShowScheduleModal(false)}
        cohortIds={[cohort.id]}
      />
    </>
  );
}
