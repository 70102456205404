import { Checkbox, Icon, Tooltip } from "components/shared";

type Props = {
  enabled: boolean;
  tooltip: string;
  setEnabled: (enabled: boolean) => void;
};

export const LabelCheckbox = ({ enabled, tooltip, setEnabled }: Props) => (
  <div className="flex items-center">
    <Tooltip
      className="ml-1 mr-2 cursor-pointer"
      content={<p className="text-wrap w-[200px]">{tooltip}</p>}
      tooltipProps={{ place: "bottom" }}
    >
      <Icon icon="info" size={4} color="text-blue-600" />
    </Tooltip>

    <Checkbox checked={enabled} onChange={() => setEnabled(!enabled)} />
  </div>
);
