import { gql } from "@apollo/client";
import {
  AccountStatus,
  CohortTeachersCard_CohortFragment,
} from "@generated/graphql";
import { getEngagementStaffMap } from "@utils/withFragments/staffing";
import { EditCohortModal } from "components/cohorts/modals/AddEditModals";
import { EmptyStateContainer, Icon } from "components/shared";
import {
  Card,
  HeaderColors,
  getEditModalOpenHeader,
} from "components/shared/Card";
import { useState } from "react";
import { getStaffRows } from "./helpers";
import { compact } from "lodash";

CohortTeachersCard.fragments = {
  cohort: gql`
    fragment CohortTeachersCard_Cohort on Cohort {
      id
      engagement {
        id
        ...GetEngagementStaffMap_Engagement
      }
      staffAssignments {
        user {
          id
          fullName
          pronouns
          accountStatus
        }
        cohortSubject
        cohortAssignmentRole
      }
      events {
        teacherAssignedId
      }
    }
    ${getEngagementStaffMap.fragments.engagement}
  `,
};

type Props = {
  className?: string;
  cohort: CohortTeachersCard_CohortFragment;
};

export function CohortTeachersCard({ className, cohort }: Props) {
  const { engagement } = cohort;
  const [showModal, setShowModal] = useState(false);
  const engagementStaffMap = getEngagementStaffMap(engagement.staffAssignments);

  const activeStaff = cohort.staffAssignments.filter(
    ({ user: { accountStatus } }) => accountStatus !== AccountStatus.Inactive
  );

  const eventAssigneeIds = compact(
    cohort.events.map((event) => event.teacherAssignedId)
  );

  return (
    <>
      <Card
        icon="teacher"
        className={className}
        headerOverlayColor={HeaderColors.Emerald}
        rows={getStaffRows(activeStaff, engagementStaffMap, eventAssigneeIds)}
        header={getEditModalOpenHeader("Teachers", () => setShowModal(true))}
      >
        {activeStaff.length < 1 && (
          <EmptyStateContainer
            className="border-0 bg-none"
            icon={<Icon icon="teacher" size={8} />}
            dataName="Teachers"
          />
        )}
      </Card>

      <EditCohortModal
        show={showModal}
        cohortId={cohort.id}
        closeModal={() => setShowModal(false)}
      />
    </>
  );
}
