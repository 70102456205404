import { CohortStudentsTable_RowFragment } from "@generated/graphql";
import { Tooltip } from "components/shared";
import addDays from "date-fns/addDays";
import { getNormalizedShortReadableDate } from "helpers/dateText";
import { CohortStudentTableRow } from "./CohortStudentsTable";

export const getCohortStudentMembershipStatus = (
  student: CohortStudentsTable_RowFragment,
  cohortStartDate: Date | null,
  cohortCreatedAtDate: Date | null
): {
  isRemoved: boolean;
  isLateAddition: boolean;
} => {
  if (!student) return { isRemoved: false, isLateAddition: false };

  const { removedAt, createdAt } = student;

  const isRemoved = Boolean(
    removedAt && new Date(removedAt).getTime() < Date.now()
  );

  const isLateAddition = Boolean(
    cohortStartDate &&
      createdAt &&
      new Date(createdAt).getTime() >
        addDays(new Date(cohortStartDate), 1).getTime() &&
      new Date(createdAt).getTime() >
        addDays(new Date(cohortCreatedAtDate ?? 0), 1).getTime()
  );

  return { isRemoved, isLateAddition };
};

export const getCohortStudentMembershipTooltip = (
  student: CohortStudentTableRow,
  children: React.ReactNode
) => {
  const {
    removalNotes,
    removedBy,
    removedAt,
    startDate,
    createdAt,
    isLateAddition,
    isRemoved,
  } = student;

  return (
    <Tooltip
      tooltipProps={{ place: "right" }}
      disabled={!(isLateAddition || isRemoved)}
      className="flex flex-center gap-1 !cursor-pointer"
      content={
        <div className="flex flex-col gap-y-2 w-fit text-base">
          <div className="flex items-center gap-x-1">
            <p className="text-sm text-white font-bold">Student ID:</p>
            <p className="text-sm text-white">{student.id}</p>
          </div>

          {isRemoved && (
            <div className="flex flex-col w-full">
              <div className="flex items-center gap-x-1">
                <p className="text-base font-bold text-rose-600">Removed On:</p>
                <p className="text-sm text-white">{`${getNormalizedShortReadableDate(
                  new Date(removedAt ?? createdAt ?? 0)
                )} by ${removedBy?.fullName ?? "an unknown user"}`}</p>
              </div>
              {removalNotes && (
                <div className="flex items-center gap-x-1">
                  <p className="text-base font-bold text-rose-600">Reason: </p>
                  <p className="text-sm text-white">{removalNotes}</p>
                </div>
              )}
            </div>
          )}

          {isLateAddition && (
            <div className="flex items-center gap-x-1">
              <p className="text-base font-bold text-emerald-600">
                Added Late On:
              </p>
              <p className="text-sm text-white">
                {getNormalizedShortReadableDate(
                  new Date(startDate ?? createdAt ?? 0)
                )}
              </p>
            </div>
          )}
        </div>
      }
    >
      {children}
    </Tooltip>
  );
};
