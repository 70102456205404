import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  EditEngagementShiftAssignmentsMutation,
  EditEngagementShiftAssignmentsMutationVariables,
  EditEngagementShiftRecordModalBody_EngagementShiftFragment,
  EditEngagementShiftRecordModalBody_EngagementStaffAssignmentFragment,
  EngagementAssignmentRole,
  EngagementShiftCellData,
} from "@generated/graphql";
import { ErrorBox, Modal } from "components/shared";
import { EngShiftAssignmentWithRole } from "components/staffAssignments/types";
import { uniqBy } from "lodash";
import groupBy from "lodash/groupBy";
import { useEffect, useMemo, useState } from "react";
import { ShiftModalEditType } from "../../../types";
import { AssignEngagementShiftTeachers } from "./AssignEngagementShiftTeachers";

EditEngagementShiftRecordModalBody.fragments = {
  engagementShift: gql`
    fragment EditEngagementShiftRecordModalBody_EngagementShift on EngagementShift {
      id
      engagementShiftAssignments {
        ...AssignEngagementShiftTeachers_EngagementShiftAssignment
      }
    }
    ${AssignEngagementShiftTeachers.fragments.engagementShiftAssignments}
  `,
  staffAssignments: gql`
    fragment EditEngagementShiftRecordModalBody_EngagementStaffAssignment on EngagementStaffAssignment {
      userId
      ...AssignEngagementShiftTeachers_EngagementStaffAssignment
    }
    ${AssignEngagementShiftTeachers.fragments.staffAssignments}
  `,
};

const EDIT_ENGAGEMENT_SHIFT_ASSIGNMENTS = gql`
  mutation EditEngagementShiftAssignments(
    $input: EditEngagementShiftAssignmentsInput!
  ) {
    editEngagementShiftAssignments(input: $input) {
      additions
      deletions
    }
  }
`;

type Props = {
  onCancel: () => void;
  onSuccess: () => void;
  shiftModalEditType: ShiftModalEditType;
  timePeriodShiftData?: EngagementShiftCellData[];
  engagementShift:
    | EditEngagementShiftRecordModalBody_EngagementShiftFragment
    | undefined;
  staffAssignments:
    | EditEngagementShiftRecordModalBody_EngagementStaffAssignmentFragment[]
    | undefined;
};

export function EditEngagementShiftRecordModalBody({
  onCancel,
  onSuccess,
  staffAssignments,
  shiftModalEditType,
  timePeriodShiftData,
  engagementShift,
}: Props) {
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [updatedStaffAssignments, setUpdatedStaffAssignments] = useState<
    EngShiftAssignmentWithRole[] | undefined
  >(undefined);

  const timePeriodShiftDataGroup = useMemo(() => {
    return groupBy(timePeriodShiftData, "engagementShiftId");
  }, [timePeriodShiftData]);

  useEffect(() => {
    if (shiftModalEditType === ShiftModalEditType.Single) {
      setUpdatedStaffAssignments(
        engagementShift?.engagementShiftAssignments?.map((shift) => {
          const foundStaffAssignment = staffAssignments?.find(
            (assignment) => assignment.userId === shift.userId
          );
          return {
            ...shift,
            engagementAssignmentRole:
              foundStaffAssignment?.engagementAssignmentRole ??
              EngagementAssignmentRole.MentorTeacher,
          };
        })
      );
    } else if (timePeriodShiftData && timePeriodShiftData.length > 0) {
      const uniqueUserIds = uniqBy(
        timePeriodShiftData.filter((userId) => userId != null),
        "userId"
      ).map((shiftData) => shiftData.userId);

      const filteredStaffAssignments = staffAssignments?.filter((assignment) =>
        uniqueUserIds.includes(assignment.userId)
      );

      setUpdatedStaffAssignments(
        filteredStaffAssignments?.map((user) => ({
          ...user,
          id: "",
          __typename: "EngagementShiftAssignment",
          engagementShiftId: "",
          userId: user.userId,
          engagementAssignmentRole:
            user.engagementAssignmentRole ??
            EngagementAssignmentRole.MentorTeacher,
        }))
      );
    }
  }, [
    engagementShift?.engagementShiftAssignments,
    shiftModalEditType,
    timePeriodShiftData,
    staffAssignments,
  ]);

  const updates = useMemo(() => {
    if (
      shiftModalEditType === ShiftModalEditType.Single &&
      engagementShift?.id
    ) {
      return [
        {
          id: engagementShift.id,
          engagementShiftAssignments: (updatedStaffAssignments ?? []).map(
            ({ userId }) => ({ userId })
          ),
        },
      ];
    }

    return Object.keys(timePeriodShiftDataGroup)
      .filter((shiftId) => shiftId !== "0")
      .map((shiftId) => ({
        id: shiftId,
        engagementShiftAssignments: (updatedStaffAssignments ?? []).map(
          ({ userId }) => ({ userId })
        ),
      }));
  }, [
    engagementShift?.id,
    shiftModalEditType,
    timePeriodShiftDataGroup,
    updatedStaffAssignments,
  ]);

  const [editEngagementShiftAssignments, { loading }] = useMutation<
    EditEngagementShiftAssignmentsMutation,
    EditEngagementShiftAssignmentsMutationVariables
  >(EDIT_ENGAGEMENT_SHIFT_ASSIGNMENTS, {
    onError: (err: ApolloError) => err?.message && setErrorMsg(err.message),
    onCompleted: onSuccess,
  });

  const onEditEngagementShiftAssignments = async () => {
    await editEngagementShiftAssignments({
      variables: { input: { updates } },
      refetchQueries: ["EngagementDetailsShifts", "CohortShiftRecordRows"],
    });
  };

  return (
    <>
      <div className="flex flex-col pt-3 gap-y-4">
        <AssignEngagementShiftTeachers
          staffAssignments={staffAssignments}
          engagementShiftId={engagementShift?.id}
          engagementShiftAssignments={updatedStaffAssignments}
          onAdd={(sa) =>
            updatedStaffAssignments
              ? setUpdatedStaffAssignments([...updatedStaffAssignments, sa])
              : setUpdatedStaffAssignments([sa])
          }
          onRemove={(sa) => {
            setUpdatedStaffAssignments(
              updatedStaffAssignments?.filter(
                (esa) => !(esa.user.id === sa.user.id)
              )
            );
          }}
        />

        <ErrorBox msg={errorMsg} />
      </div>

      <Modal.Buttons>
        <Modal.Button
          type="confirm"
          onClick={onEditEngagementShiftAssignments}
          loading={loading}
        >
          Confirm
        </Modal.Button>
        <Modal.Button type="cancel" onClick={onCancel}>
          Cancel
        </Modal.Button>
      </Modal.Buttons>
    </>
  );
}
