import { EngagementAssignmentRole } from "@generated/graphql";
import {
  AccountRoleBadge,
  CohortAssignmentSubjectBadge,
  EngagementAssignmentRoleBadge,
  Table,
} from "components/shared";
import { Icon } from "components/shared/Icon";
import { useMemo } from "react";
import { CellProps, Column } from "react-table";
import { AssignCommonStaffAssignment } from "./types";
import {
  isAssignEngagementShiftTeacher,
  isAssignEngagementStaffTeacher,
} from "./utils";

type Props = {
  roleType: "Engagement" | "Cohort" | "Shift";
  staffAssignments?: AssignCommonStaffAssignment[];
  onRemove: (teacher: AssignCommonStaffAssignment) => void;
};

export const StaffAssignmentTable = ({
  staffAssignments,
  roleType,
  onRemove,
}: Props) => {
  const tableData: StaffAssignmentRow[] = useMemo(
    () =>
      (staffAssignments ?? []).map((sa, i) => ({
        id: `${sa.user.id}_${i}`,
        staffAssignment: sa,
      })),
    [staffAssignments]
  );

  return (
    <Table
      columns={getStaffAssignmentColumns(onRemove, roleType)}
      data={tableData}
      dataName={`${roleType} Teachers`}
      emptyStateIcon={<Icon icon="teacher" size={7} />}
      className="min-h-0"
      showPagination={false}
    />
  );
};

type StaffAssignmentRow = {
  id: string;
  staffAssignment: AssignCommonStaffAssignment;
};

const getStaffAssignmentColumns = (
  onRemove: (teacher: AssignCommonStaffAssignment) => void,
  roleType: string
): Column<StaffAssignmentRow>[] => {
  const getBadge = (staffAssignment: AssignCommonStaffAssignment) =>
    isAssignEngagementStaffTeacher(staffAssignment) ||
    isAssignEngagementShiftTeacher(staffAssignment) ? (
      <EngagementAssignmentRoleBadge
        role={
          staffAssignment.engagementAssignmentRole ??
          EngagementAssignmentRole.MentorTeacher
        }
      />
    ) : (
      <CohortAssignmentSubjectBadge
        cohortSubject={staffAssignment.cohortSubject}
      />
    );

  return [
    {
      Header: "Staff",
      Cell: ({
        row: {
          original: {
            staffAssignment: { user },
          },
        },
      }: CellProps<StaffAssignmentRow>) => (
        <div className="flex flex-col">
          <span className="text-md font-medium text-gray-800">
            {user.fullName}
          </span>
          <span>{user.email}</span>
        </div>
      ),
    },
    {
      Header: "Account Role",
      Cell: ({
        row: {
          original: {
            staffAssignment: { user },
          },
        },
      }: CellProps<StaffAssignmentRow>) => (
        <AccountRoleBadge accountRole={user.accountRole} />
      ),
    },
    {
      Header: `${roleType} Role`,
      Cell: ({
        row: {
          original: { staffAssignment },
        },
      }: CellProps<StaffAssignmentRow>) => getBadge(staffAssignment),
    },
    {
      Header: " ",
      width: "!w-5",
      Cell: ({
        row: {
          original: { staffAssignment },
        },
      }: CellProps<StaffAssignmentRow>) => (
        <button onClick={() => onRemove(staffAssignment)}>
          <Icon
            icon="remove"
            size={6}
            color="text-gray-500 hover:text-rose-800"
          />
          <span className="sr-only">Remove</span>
        </button>
      ),
    },
  ];
};
