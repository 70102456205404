import { optionsArrayContainerItemStyle } from "@utils/styleStrings";
import clsx from "clsx";
import {
  disabledDay,
  ErrorBox,
  Icon,
  Modal,
  NavigationCalendars,
} from "components/shared";
import { useLayout } from "contexts/LayoutProvider";
import { format, isSameDay, subWeeks } from "date-fns";
import dayjs from "dayjs";
import { isEqual } from "lodash";
import { useState } from "react";
import { OptionsArrayContainer } from "../Inputs/OptionsArrayContainer";
import { OnUpdateViewpoint } from "../NavigationCalendars/types";
import { specialDaysCellRenderer } from "./helpers";
import { DayType } from "./types";

type Props = {
  endDate: Date;
  startDate: Date;
  dayType: DayType;
  loading: boolean;
  errorMsg: string | null;
  primarySpecialDays: Date[];
  secondarySpecialDays?: Date[];
  closeModal: () => void;
  onEdit: (specialDays: Date[]) => void;
};

export const SpecialDaysModalBody = ({
  endDate,
  loading,
  dayType,
  errorMsg,
  startDate,
  primarySpecialDays,
  secondarySpecialDays = [],
  onEdit,
  closeModal,
}: Props) => {
  const { screenWidth } = useLayout();
  const initDate = subWeeks(new Date(startDate), 3);
  const [specialDays, setSpecialDays] = useState(primarySpecialDays);

  const toggleDate = (date: Date) =>
    setSpecialDays((days) =>
      days.some((d) => isSameDay(d, date))
        ? days.filter((d) => !isSameDay(date, d))
        : [...days, date]
    );

  const cellRender = (current: dayjs.Dayjs, month: dayjs.Dayjs) =>
    specialDaysCellRenderer(current, month, specialDays, secondarySpecialDays);

  const renderSpecialDayItems = specialDays
    .sort((a, b) => a.getTime() - b.getTime())
    .map((day, i) => (
      <div
        key={i}
        onClick={() => toggleDate(day)}
        className={clsx("!w-[130px]", optionsArrayContainerItemStyle("remove"))}
      >
        <div className="pl-1">{format(day, "MMM d, yyyy")}</div>
        <Icon icon="remove" size={5} color="text-rose-600" />
      </div>
    ));

  return (
    <div className="flex flex-col h-auto mt-8 gap-6 w-full">
      <div className="flex h-fit relative flex-center w-full gap-1">
        <NavigationCalendars
          viewDate={initDate}
          className="justify-center"
          onUpdateViewpoint={OnUpdateViewpoint.Static}
          calendarsWidth={Math.floor(screenWidth * 0.9 - 90)}
          setViewDate={toggleDate}
          cellRenderer={cellRender}
          disabledDayFn={(date) => disabledDay(date, startDate, endDate)}
        />
      </div>

      <OptionsArrayContainer
        className="px-8"
        minHeight="min-h-[90px]"
        items={renderSpecialDayItems}
        label={`Selected ${dayType} Days`}
        hasItems={specialDays.length > 0}
        noItemsText={`Zero ${dayType} days have been selected`}
      />

      <ErrorBox msg={errorMsg} className="px-1 lg:px-10" />

      <Modal.Buttons>
        <Modal.Button
          type="confirm"
          loading={loading}
          onClick={() => onEdit(specialDays)}
          disabled={isEqual(primarySpecialDays, specialDays)}
        >
          Save
        </Modal.Button>

        <Modal.Button
          type="cancel"
          onClick={() => {
            closeModal();
            setSpecialDays(primarySpecialDays);
          }}
        >
          Cancel
        </Modal.Button>
      </Modal.Buttons>
    </div>
  );
};
