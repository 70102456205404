import { gql } from "@apollo/client";
import {
  AccountRole,
  AddUsersInput_UserFragment,
  AssignEngagementShiftTeachers_EngagementStaffAssignmentFragment,
  EngagementAssignmentRole,
} from "@generated/graphql";
import { FieldError } from "components/shared";
import { AddUsersInput } from "components/staffAssignments/AddUsersInput";
import { StaffAssignmentTable } from "components/staffAssignments/StaffAssignmentTable";
import { EngShiftAssignmentWithRole } from "components/staffAssignments/types";
import { isAssignEngagementShiftTeacher } from "components/staffAssignments/utils";
import { compact, noop } from "lodash";
import { useMemo, useState } from "react";

AssignEngagementShiftTeachers.fragments = {
  engagementShiftAssignments: gql`
    fragment AssignEngagementShiftTeachers_EngagementShiftAssignment on EngagementShiftAssignment {
      id
      userId
      engagementShiftId
      user {
        ...AddUsersInput_User
      }
    }
    ${AddUsersInput.fragments.user}
  `,
  staffAssignments: gql`
    fragment AssignEngagementShiftTeachers_EngagementStaffAssignment on EngagementStaffAssignment {
      userId
      engagementAssignmentRole
      user {
        ...AddUsersInput_User
      }
    }
    ${AddUsersInput.fragments.user}
  `,
};

type Props = {
  engagementShiftId?: string;
  engagementShiftAssignments: EngShiftAssignmentWithRole[] | undefined;
  staffAssignments?: AssignEngagementShiftTeachers_EngagementStaffAssignmentFragment[];
  onAdd: (shiftAssignment: EngShiftAssignmentWithRole) => void;
  onRemove: (shiftAssignment: EngShiftAssignmentWithRole) => void;
};

export function AssignEngagementShiftTeachers({
  staffAssignments,
  engagementShiftId,
  engagementShiftAssignments,
  onAdd,
  onRemove,
}: Props) {
  const { TutorTeacher, MentorTeacher } = AccountRole;
  const [error, setError] = useState<string | null>(null);

  const addUser = (user: AddUsersInput_UserFragment | null) => {
    if (!user) return setError("Please select a user.");

    const selectionKey = `${user.id}`;
    const keys = engagementShiftAssignments?.map((sa) => `${sa.user.id}`);
    const role = staffAssignments?.find((sa) => sa.userId === user.id);

    if (keys?.includes(selectionKey))
      return setError("This user has already been assigned to this role!");

    setError(null);
    onAdd({
      id: "",
      user,
      userId: user.id,
      engagementAssignmentRole:
        role?.engagementAssignmentRole ??
        EngagementAssignmentRole.MentorTeacher,
      engagementShiftId: engagementShiftId ?? "",
    });
  };

  const userOptions = useMemo(() => {
    const staffedIds: string[] = compact(
      (engagementShiftAssignments ?? [])
        .filter((s) => isAssignEngagementShiftTeacher(s))
        .map(({ userId }) => userId)
    );

    return (staffAssignments ?? [])
      .filter(({ user }) => !staffedIds.includes(user.id))
      .map(({ user }) => user);
  }, [staffAssignments, engagementShiftAssignments]);

  return (
    <div className="flex flex-col gap-y-4">
      <AddUsersInput
        userTerm="Teacher"
        title="Select Teachers"
        predefinedUsers={userOptions}
        userSearchAccountRoleAllowList={[TutorTeacher, MentorTeacher]}
        onClickAdd={addUser}
        onSelect={() => setError(null)}
      />

      <FieldError msg={error} className="mt-[-10px]" />

      <StaffAssignmentTable
        roleType="Shift"
        staffAssignments={engagementShiftAssignments}
        onRemove={(staffAssignment) =>
          isAssignEngagementShiftTeacher(staffAssignment)
            ? onRemove(staffAssignment)
            : noop
        }
      />
    </div>
  );
}
