import { gql } from "@apollo/client";
import {
  CohortStudentsCard_CohortFragment,
  CohortStudentsTable_RowFragment,
} from "@generated/graphql";
import { AddStudentsModal } from "components/cohorts/CohortDetailsPage/components/AddStudentsModal";
import {
  Card,
  HeaderColors,
  getEditModalOpenHeader,
} from "components/shared/Card";
import { CohortStudentsTable } from "components/students/CohortStudentsTable/CohortStudentsTable";
import { useState } from "react";
import { RemoveCohortStudentModal } from "./components";

CohortStudentsCard.fragments = {
  cohort: gql`
    fragment CohortStudentsCard_Cohort on Cohort {
      id
      name
      endDate
      startDate
      createdAt
      students {
        ...CohortStudentsTable_Row
      }
      engagement {
        id
        organizationId
      }
    }
    ${CohortStudentsTable.fragments.cohortStudent}
  `,
};

type CohortStudent = CohortStudentsTable_RowFragment;

type Props = {
  className?: string;
  cohort: CohortStudentsCard_CohortFragment;
};

export function CohortStudentsCard({ cohort, className }: Props) {
  const { name, startDate, endDate, createdAt, students } = cohort;
  const [cohortStudent, setCohortStudent] = useState<CohortStudent>();
  const [showAddStudentsModal, setShowAddStudentsModal] = useState(false);

  return (
    <>
      <Card
        icon="student"
        className={className}
        headerOverlayColor={HeaderColors.Violet}
        header={getEditModalOpenHeader("Students", () =>
          setShowAddStudentsModal(true)
        )}
      >
        <CohortStudentsTable
          isSimpleTable
          students={students}
          cohortStartDate={startDate}
          cohortCreatedAtDate={createdAt}
          className="rounded-t-none border-none"
          removeStudent={setCohortStudent}
        />
      </Card>

      <RemoveCohortStudentModal
        show={Boolean(cohortStudent)}
        closeModal={() => setCohortStudent(undefined)}
        cohortStudent={cohortStudent}
        cohortName={name}
        cohortStartDate={startDate}
        cohortEndDate={endDate}
      />

      <AddStudentsModal
        cohortName={name}
        cohortId={cohort.id}
        show={showAddStudentsModal}
        closeModal={() => setShowAddStudentsModal(false)}
        organizationId={cohort.engagement.organizationId}
        removedCohortStudentsIds={students
          .filter(({ removedAt }) => removedAt)
          .map(({ studentId }) => studentId)}
      />
    </>
  );
}
