import { gql } from "@apollo/client";
import {
  CohortShiftsPanel_CohortShiftRecordRowFragment,
  EngagementAssignmentRole,
} from "@generated/graphql";
import { EngagementAssignmentRoleBadge, Icon } from "components/shared";
import { EmptyStateContainer } from "components/shared/EmptyStateContainer";
import { OptionsArrayContainer } from "components/shared/Inputs/OptionsArrayContainer";
import {
  dayMapping,
  days,
} from "sections/Engagements/components/EngagementDetailsPage/components/EngagementDetailsTabs/tabs/EngagementShiftTab/constants";

const COHORT_SHIFT_CELL_DATA = gql`
  fragment CohortShiftsPanel_CohortEngagementShiftCellData on CohortEngagementShiftCellData {
    data {
      engagementShiftId
      fullName
      userId
      engagementAssignmentRole
    }
    timeSlot
    engagementShiftId
  }
`;

CohortShiftsPanel.fragments = {
  cohortShiftRecordRow: gql`
    fragment CohortShiftsPanel_CohortShiftRecordRow on CohortShiftRecordRow {
      SU {
        ...CohortShiftsPanel_CohortEngagementShiftCellData
      }
      MO {
        ...CohortShiftsPanel_CohortEngagementShiftCellData
      }
      TU {
        ...CohortShiftsPanel_CohortEngagementShiftCellData
      }
      WE {
        ...CohortShiftsPanel_CohortEngagementShiftCellData
      }
      TH {
        ...CohortShiftsPanel_CohortEngagementShiftCellData
      }
      FR {
        ...CohortShiftsPanel_CohortEngagementShiftCellData
      }
      SA {
        ...CohortShiftsPanel_CohortEngagementShiftCellData
      }
    }
    ${COHORT_SHIFT_CELL_DATA}
  `,
};

type Props = {
  openEditModalHandler: (id: string | null) => void;
  cohortShiftRecordRows:
    | CohortShiftsPanel_CohortShiftRecordRowFragment
    | null
    | undefined;
  emptyStateClassName?: string;
};

export function CohortShiftsPanel({
  openEditModalHandler,
  cohortShiftRecordRows,
  emptyStateClassName = "",
}: Props) {
  return (
    <div className="divide-y divide-gray-200">
      {days.filter(
        (day) => cohortShiftRecordRows && cohortShiftRecordRows[day]?.length
      ).length === 0 ? (
        <EmptyStateContainer
          dataName="Shifts"
          className={emptyStateClassName}
          icon={<Icon icon="calendarEvents" size={8} />}
        />
      ) : (
        days.map((day) => {
          if (cohortShiftRecordRows && cohortShiftRecordRows[day]?.length) {
            return (
              <div
                key={day}
                className="py-2 px-3 odd:bg-slate-50 even:bg-slate-100"
              >
                {cohortShiftRecordRows[day]?.map(
                  ({ timeSlot, data, engagementShiftId }, index) => {
                    const items = (data ?? [])
                      .filter((row) => row.fullName)
                      .sort((a, b) =>
                        (a.fullName ?? "").localeCompare(b.fullName ?? "")
                      );

                    const teacherItems = items.map((shiftRecordRow) => (
                      <EngagementAssignmentRoleBadge
                        key={shiftRecordRow.fullName}
                        role={
                          shiftRecordRow.engagementAssignmentRole ??
                          EngagementAssignmentRole.MentorTeacher
                        }
                        teacherName={shiftRecordRow.fullName ?? "Unknown"}
                      />
                    ));

                    return (
                      <div className="flex w-full gap-4 h-auto" key={timeSlot}>
                        <div className="flex flex-col">
                          {index === 0 && (
                            <div className="w-full text-md font-semibold leading-5 text-slate-700 min-w-[90px]">
                              {dayMapping[day]}
                            </div>
                          )}
                          <div className="flex h-full justify-start leading-5 items-center text-sm font-medium text-slate-700">
                            {timeSlot}
                          </div>
                        </div>
                        <OptionsArrayContainer
                          noItemsText="No teachers assigned"
                          hasItems={items.length > 0}
                          onClick={() =>
                            openEditModalHandler(engagementShiftId ?? null)
                          }
                          minHeight="min-h-[40px]"
                          itemsContainerClassName="min-h-[40px] gap-2"
                          items={teacherItems}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            );
          }
          return null;
        })
      )}
    </div>
  );
}
