import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import {
  AddStudentsModalDataQuery,
  AddStudentsToCohortMutation,
  AddStudentsToCohortMutationVariables,
  CohortStudentsTable_RowFragment,
  DraftCohortStudentInput,
  DraftStudentInput,
} from "@generated/graphql";
import { normalizeToUtcDate } from "@utils/dateTime";
import { fetchErrToast } from "@utils/errorLogging";
import { COHORT_DETAILS_PAGE_QUERY_NAME } from "components/cohorts/constants";
import { ErrorBox, Modal, OptionsToggleButton } from "components/shared";
import { CohortStudentsTable } from "components/students/CohortStudentsTable/CohortStudentsTable";
import { uniqBy } from "lodash";
import { useMemo, useRef, useState } from "react";
import {
  COHORT_DETAILS_SIDEBAR,
  ENGAGEMENT_DETAILS_COHORTS_QUERY_NAME,
  ENGAGEMENT_DETAILS_COHORTS_TABLE_QUERY_NAME,
  ENGAGEMENT_DETAILS_STUDENTS_QUERY_NAME,
} from "sections/Engagements/constants";
import { NewStudentFields } from "./NewStudentFields";
import { StudentSearchBox } from "./StudentSearchBox";

export enum AddStudentMode {
  Existing = "Add Existing Students",
  New = "Add New Students",
}

const ADD_STUDENTS_MODAL_DATA_QUERY = gql`
  query AddStudentsModalData($cohortId: ID!) {
    cohort(id: $cohortId) {
      id
      startDate
      createdAt
      students {
        id
        startDate
        student {
          id
          grade
          school
          fullName
          createdAt
          externalId
          classroomTeacherName
        }
        ...CohortStudentsTable_Row
      }
      ...StudentSearchBox_Cohort
      ...NewStudentFields_Cohort
    }
  }
  ${StudentSearchBox.fragments.cohort}
  ${NewStudentFields.fragments.cohort}
  ${CohortStudentsTable.fragments.cohortStudent}
`;

const ADD_STUDENTS_TO_COHORT = gql`
  mutation AddStudentsToCohort($input: [DraftCohortStudentInput!]!) {
    addStudentsToCohort(input: $input)
  }
`;

type Props = {
  cohortId: string;
  organizationId: string;
  removedCohortStudentsIds?: string[];
  closeModal: () => void;
};

export const AddStudentsModalBody = ({
  cohortId,
  organizationId,
  removedCohortStudentsIds,
  closeModal,
}: Props) => {
  const { Existing } = AddStudentMode;
  const [errorMsg, setErrorMsg] = useState("");
  const modalRef = useRef<HTMLDivElement>(null);
  const [addMode, setAddMode] = useState<AddStudentMode>(Existing);
  const [cohort, setCohort] = useState<AddStudentsModalDataQuery["cohort"]>();

  const [draftCohortStudents, setDraftCohortStudents] = useState<
    DraftCohortStudentInput[]
  >([]);

  const { data, refetch } = useQuery<AddStudentsModalDataQuery>(
    ADD_STUDENTS_MODAL_DATA_QUERY,
    {
      variables: { cohortId },
      fetchPolicy: "network-only",
      onCompleted: (data) => setCohort(data.cohort),
      onError: (error) => fetchErrToast("cohort student data", error),
    }
  );

  const [addStudentsToCohort, { loading }] = useMutation<
    AddStudentsToCohortMutation,
    AddStudentsToCohortMutationVariables
  >(ADD_STUDENTS_TO_COHORT, {
    onError: (err: ApolloError) => setErrorMsg(err.message),
    onCompleted: () => {
      setErrorMsg("");
      closeModal();
      refetch();
    },
    refetchQueries: [
      COHORT_DETAILS_PAGE_QUERY_NAME,
      ENGAGEMENT_DETAILS_COHORTS_QUERY_NAME,
      ENGAGEMENT_DETAILS_COHORTS_TABLE_QUERY_NAME,
      ENGAGEMENT_DETAILS_STUDENTS_QUERY_NAME,
      COHORT_DETAILS_SIDEBAR,
    ],
  });

  const onCreateStudentsForCohort = async () =>
    await addStudentsToCohort({
      variables: {
        input: draftCohortStudents.map(({ startDate, student }) => ({
          cohortId,
          student: {
            organizationId,
            fullName: student.fullName,
            externalId: student.externalId,
            grade: student.grade,
            school: student.school,
            classroomTeacherName: student.classroomTeacherName,
          },
          startDate: startDate
            ? normalizeToUtcDate(new Date(startDate)).getTime()
            : undefined,
        })),
      },
    });

  const addStudent = (
    student: DraftStudentInput,
    startDate: Date | null | undefined
  ) =>
    setDraftCohortStudents((prevStudents) => [
      ...prevStudents,
      { cohortId, student, startDate },
    ]);

  const removeStudent = ({ student }: CohortStudentsTable_RowFragment) =>
    setDraftCohortStudents((prevStudents) =>
      prevStudents.filter((s) => s.student.externalId !== student.externalId)
    );

  const savedActiveStudents = useMemo(() => {
    return (data?.cohort?.students ?? []).filter(
      ({ student }) => !removedCohortStudentsIds?.includes(student.id)
    );
  }, [data?.cohort?.students, removedCohortStudentsIds]);

  return (
    <div className="flex flex-col gap-y-3">
      <div className="flex flex-col w-full my-4 gap-y-5" ref={modalRef}>
        <OptionsToggleButton
          height="h-12"
          titleClassName="text-[16px] font-semibold"
          activeTab={Object.values(AddStudentMode).indexOf(addMode)}
          options={Object.values(AddStudentMode).map((type) => type)}
          setActiveTab={(i) => setAddMode(Object.values(AddStudentMode)[i])}
        />

        <div className="rounded-lg flex-col bg-gray-100/50 border border-gray-300 shadow-inner p-4 mx-1 h-[166px]">
          {addMode === Existing ? (
            <StudentSearchBox
              cohort={cohort ?? null}
              organizationId={organizationId}
              currentStudents={draftCohortStudents}
              savedStudentIds={savedActiveStudents.map((s) => s.student.id)}
              addStudent={addStudent}
            />
          ) : (
            <NewStudentFields
              cohort={cohort ?? null}
              organizationId={organizationId}
              addStudent={addStudent}
            />
          )}
        </div>
      </div>

      <div className="block text-lg font-bold text-gray-600 leading-none">
        Cohort Students
      </div>

      <CohortStudentsTable
        removeStudent={removeStudent}
        cohortStartDate={data?.cohort?.startDate ?? null}
        cohortCreatedAtDate={data?.cohort?.createdAt ?? null}
        students={uniqBy(
          [
            ...draftCohortStudents.map((s) => ({
              id: String(s.student.externalId),
              externalId: s.student.externalId,
              student: {
                ...s.student,
                id: String(s.student.externalId),
              },
              studentId: s.student.externalId,
              createdAt: new Date(),
              startDate: s.startDate,
            })),
            ...savedActiveStudents.map((s) => ({ ...s, cannotRemove: true })),
          ],
          "student.externalId"
        )}
      />

      <ErrorBox msg={errorMsg} />

      <Modal.Buttons>
        <Modal.Button
          type="confirm"
          onClick={onCreateStudentsForCohort}
          loading={loading}
        >
          Save
        </Modal.Button>
        <Modal.Button
          type="cancel"
          onClick={() => {
            closeModal();
            setErrorMsg("");
          }}
        >
          Cancel
        </Modal.Button>
      </Modal.Buttons>
    </div>
  );
};
