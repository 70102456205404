import { gql, useQuery } from "@apollo/client";
import {
  CohortShiftRecordRowsQuery,
  CohortShiftRecordRowsQueryVariables,
  CohortShiftsCard_CohortFragment,
} from "@generated/graphql";
import { fetchErrToast } from "@utils/errorLogging";
import { Card, HeaderColors } from "components/shared/Card";
import { useEffect, useState } from "react";
import EditEngagementShiftRecordModal from "sections/Engagements/components/EngagementDetailsPage/components/EngagementDetailsTabs/tabs/EngagementShiftTab/components/EditEngagementShiftRecordModal/EditEngagementShiftRecordModal";
import { CohortShiftsPanel } from "./components/CohortShiftsPanel";

CohortShiftsCard.fragments = {
  cohort: gql`
    fragment CohortShiftsCard_Cohort on Cohort {
      id
      engagement {
        id
        ...EditEngagementShiftRecordModal_Engagement
      }
    }
    ${EditEngagementShiftRecordModal.fragments.engagement}
  `,
};

const COHORT_SHIFT_RECORD_ROWS = gql`
  query CohortShiftRecordRows($cohortId: ID!, $engagementId: ID!) {
    cohortShiftRecordRows(cohortId: $cohortId, engagementId: $engagementId) {
      ...CohortShiftsPanel_CohortShiftRecordRow
    }
  }
  ${CohortShiftsPanel.fragments.cohortShiftRecordRow}
`;

type Props = {
  cohort: CohortShiftsCard_CohortFragment;
};

export function CohortShiftsCard({ cohort }: Props) {
  const [currShiftId, setCurrShiftId] = useState<string | null>(null);
  const [showEngagementShiftModal, setShowEngagementShiftModal] =
    useState(false);

  const openEditModalHandler = (id: string | null) => {
    setCurrShiftId(id);
    setShowEngagementShiftModal(true);
  };

  const closeModalHandler = () => {
    setShowEngagementShiftModal(false);
    setCurrShiftId(null);
  };

  const { data, refetch } = useQuery<
    CohortShiftRecordRowsQuery,
    CohortShiftRecordRowsQueryVariables
  >(COHORT_SHIFT_RECORD_ROWS, {
    fetchPolicy: "network-only",
    variables: { cohortId: cohort.id, engagementId: cohort.engagement.id },
    onError: (error) => fetchErrToast("cohort shifts data.", error),
  });

  useEffect(() => {
    refetch({ cohortId: cohort.id, engagementId: cohort.engagement.id });
  }, [cohort.engagement.id, cohort.id, refetch]);

  return (
    <>
      <Card
        header="Shifts"
        icon="calendarEvents"
        headerOverlayColor={HeaderColors.Indigo}
      >
        <CohortShiftsPanel
          openEditModalHandler={openEditModalHandler}
          cohortShiftRecordRows={data?.cohortShiftRecordRows}
          emptyStateClassName="border-none"
        />
      </Card>

      <EditEngagementShiftRecordModal
        engagementShiftId={currShiftId}
        show={showEngagementShiftModal}
        closeModal={closeModalHandler}
        engagement={cohort.engagement}
      />
    </>
  );
}
