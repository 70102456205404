import { gql } from "@apollo/client";
import { CohortSpecialDaysCard_CohortFragment } from "@generated/graphql";
import { Card } from "components/shared";
import { HeaderColors } from "components/shared/Card";
import {
  DayType,
  Entity,
  SpecialDaysModal,
  SpecialDaysScrollContainer,
} from "components/shared/SpecialDays";
import {
  assessmentDaysTooltip,
  cancellationDaysTooltip,
  holidayTooltip,
  normalize,
  noShowDayTooltip,
  noTutoringDayTooltip,
} from "components/shared/SpecialDays/helpers";
import { useMemo, useState } from "react";

type SpecialDaysRowDetails = {
  tooltip: React.ReactNode;
  title: string;
  dates: Date[];
  onClick?: () => void;
};

CohortSpecialDaysCard.fragments = {
  cohort: gql`
    fragment CohortSpecialDaysCard_Cohort on Cohort {
      id
      name
      endDate
      startDate
      studentNoShowDays
      studentAssessmentDays
      cancellationDays
      engagement {
        id
        usHolidays
        noTutoringDays
        studentNoShowDays
        studentAssessmentDays
      }
    }
  `,
};

type Props = {
  cohort: CohortSpecialDaysCard_CohortFragment;
  readOnly?: boolean;
  hideEmpty?: boolean;
  className?: string;
};

export function CohortSpecialDaysCard({
  cohort,
  className,
  readOnly = false,
  hideEmpty = false,
}: Props) {
  let hasSpecialDays = false;
  const { Assessment, NoShow } = DayType;
  const [specialDayType, setSpecialDayType] = useState<DayType>(NoShow);
  const [showSpecialDaysModal, setShowSpecialDaysModal] = useState(false);

  const { engagement, studentNoShowDays, studentAssessmentDays } = cohort;
  const { studentAssessmentDays: engAssessmentDays } = engagement;
  const { studentNoShowDays: engNoShowDays } = engagement;

  const normalizedEngagementNoShowDays = normalize(engNoShowDays);
  const normalizedEngagementAssessmentDays = normalize(engAssessmentDays);
  const normalizedCohortNoShowDays = normalize(studentNoShowDays);
  const normalizedCohortAssessmentDays = normalize(studentAssessmentDays);

  const { primarySpecialDays, secondarySpecialDays } = useMemo(
    () => ({
      primarySpecialDays:
        specialDayType === DayType.NoShow
          ? normalizedCohortNoShowDays
          : normalizedCohortAssessmentDays,
      secondarySpecialDays:
        specialDayType === DayType.Assessment
          ? normalizedEngagementNoShowDays
          : normalizedEngagementAssessmentDays,
    }),
    [
      normalizedCohortAssessmentDays,
      normalizedCohortNoShowDays,
      normalizedEngagementAssessmentDays,
      normalizedEngagementNoShowDays,
      specialDayType,
    ]
  );

  const rowDetails: SpecialDaysRowDetails[] = [
    {
      tooltip: noShowDayTooltip(false),
      title: "Engagement No-Show Days",
      dates: normalizedEngagementNoShowDays,
    },
    {
      tooltip: noShowDayTooltip(true),
      title: "Cohort No-Show Days",
      dates: normalizedCohortNoShowDays,
      onClick: readOnly
        ? undefined
        : () => {
            setSpecialDayType(NoShow);
            setShowSpecialDaysModal(true);
          },
    },
    {
      tooltip: assessmentDaysTooltip(false),
      title: "Engagement Assessment Days",
      dates: normalizedEngagementAssessmentDays,
    },
    {
      tooltip: assessmentDaysTooltip(true),
      title: "Cohort Assessment Days",
      dates: normalizedCohortAssessmentDays,
      onClick: readOnly
        ? undefined
        : () => {
            setSpecialDayType(Assessment);
            setShowSpecialDaysModal(true);
          },
    },
    {
      tooltip: noTutoringDayTooltip(),
      title: "No Tutoring Days",
      dates: normalize(engagement.noTutoringDays),
    },
    {
      tooltip: holidayTooltip(),
      title: "US Holidays",
      dates: normalize(engagement.usHolidays),
    },
    {
      tooltip: cancellationDaysTooltip(),
      title: "Days with Cancellations",
      dates: normalize(cohort.cancellationDays),
    },
  ];

  hasSpecialDays = rowDetails.some(({ dates }) => dates.length > 0);

  return !hideEmpty || hasSpecialDays ? (
    <>
      <Card
        icon="calendarDays"
        header="Special Days"
        className={className}
        headerOverlayColor={HeaderColors.Rose}
        rows={rowDetails
          .filter(({ dates }) => !hideEmpty || dates.length > 0)
          .map((row) => [
            row.tooltip,
            <SpecialDaysScrollContainer
              key={0}
              onClick={row.onClick}
              specialDays={row.dates}
              maxHeight="max-h-[112px]"
              noDaysMessage={`No ${row.title}`}
            />,
          ])}
      />

      <SpecialDaysModal
        entityId={cohort.id}
        entity={Entity.Cohort}
        dayType={specialDayType}
        entityName={cohort.name}
        endDate={cohort.endDate}
        startDate={cohort.startDate}
        refetchQueries={["CohortDetailsPage"]}
        primarySpecialDays={primarySpecialDays}
        show={showSpecialDaysModal && !readOnly}
        secondarySpecialDays={secondarySpecialDays}
        closeModal={() => setShowSpecialDaysModal(false)}
      />
    </>
  ) : null;
}
