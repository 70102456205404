import { gql } from "@apollo/client";
import { CohortToDraftEvents_CohortFragment } from "@generated/graphql";
import { getEngagementStaffMap } from "@utils/withFragments/staffing";
import {
  CohortScheduler,
  cohortToDraftEvents,
} from "components/cohorts/CohortScheduler";
import { CohortsScheduleCalendarModal } from "components/cohorts/CohortsScheduleCalendar";
import { EditCohortModal } from "components/cohorts/modals/AddEditModals";
import {
  Card,
  getEditModalOpenHeader,
  HeaderColors,
} from "components/shared/Card";
import { useMemo, useState } from "react";

CohortScheduleCard.fragments = {
  cohort: gql`
    fragment CohortScheduleCard_Cohort on Cohort {
      id
      engagement {
        id
        ...GetEngagementStaffMap_Engagement
      }
      ...CohortToDraftEvents_Cohort
    }
    ${cohortToDraftEvents.fragments.cohort}
    ${getEngagementStaffMap.fragments.engagement}
  `,
};

type Props = {
  className?: string;
  cohort: CohortToDraftEvents_CohortFragment;
};

export function CohortScheduleCard({ cohort, className }: Props) {
  const [showModal, setShowModal] = useState(false);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const draftEvents = useMemo(() => cohortToDraftEvents(cohort), [cohort]);

  return (
    <>
      <Card
        icon="calendar"
        className={className}
        headerOverlayColor={HeaderColors.Pink}
        header={getEditModalOpenHeader("Schedule", () => setShowModal(true))}
      >
        <CohortScheduler
          draftEvents={draftEvents}
          onChange={() => null}
          readOnly={true}
        />
      </Card>

      <EditCohortModal
        show={showModal}
        cohortId={cohort.id}
        closeModal={() => setShowModal(false)}
      />

      <CohortsScheduleCalendarModal
        show={showScheduleModal}
        closeModal={() => setShowScheduleModal(false)}
        cohortIds={[cohort.id]}
      />
    </>
  );
}
