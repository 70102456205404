import { EngagementShiftCellData } from "@generated/graphql";

export const days: DayOfWeek[] = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];

export const dayMapping: Record<DayOfWeek, string> = {
  SU: "Sunday",
  MO: "Monday",
  TU: "Tuesday",
  WE: "Wednesday",
  TH: "Thursday",
  FR: "Friday",
  SA: "Saturday",
};

export type DayOfWeek = "SU" | "MO" | "TU" | "WE" | "TH" | "FR" | "SA";

export type ShiftRecordRow = {
  timeSlot: string;
  id: string;
  engagementId: string;
  days: Record<DayOfWeek, EngagementShiftCellData[]>;
};
