import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  EditCohortSpecialDaysMutation,
  EditEngagementSpecialDaysMutation,
} from "@generated/graphql";
import { normalizeToUtcDate } from "@utils/dateTime";
import { Modal } from "components/shared";
import { SpecialDaysModalBody } from "components/shared/SpecialDays";
import { noop, uniq } from "lodash";
import { useState } from "react";
import { DayType, Entity } from "./types";
import { getSpecialDaysModalSubtitle } from "./utils";

const UPDATE_ENG_SPECIAL_DAYS = gql`
  mutation EditEngagementSpecialDays($input: EditEngagementInput!) {
    editEngagement(input: $input) {
      id
    }
  }
`;

const UPDATE_COHORT_SPECIAL_DAYS = gql`
  mutation EditCohortSpecialDays($input: EditCohortInput!) {
    editCohort(input: $input) {
      id
    }
  }
`;

type Props = {
  show: boolean;
  endDate: Date;
  entity: Entity;
  startDate: Date;
  dayType: DayType;
  entityId: string;
  entityName: string;
  refetchQueries: string[];
  primarySpecialDays: Date[];
  secondarySpecialDays?: Date[];
  closeModal: () => void;
};

export function SpecialDaysModal({
  show,
  entity,
  endDate,
  entityId,
  startDate,
  entityName,
  primarySpecialDays,
  refetchQueries = [],
  secondarySpecialDays,
  dayType,
  closeModal,
}: Props) {
  const isNoShowDays = dayType === DayType.NoShow;
  const isCohortEntity = entity === Entity.Cohort;
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const onCompleted = () => {
    closeModal();
    setErrorMsg(null);
  };

  const [editEngagementSpecialDays, { loading: loadingEngagement }] =
    useMutation<EditEngagementSpecialDaysMutation>(UPDATE_ENG_SPECIAL_DAYS, {
      onError: (err: ApolloError) => setErrorMsg(err.message),
      onCompleted,
    });

  const [editCohortSpecialDays, { loading: loadingCohort }] =
    useMutation<EditCohortSpecialDaysMutation>(UPDATE_COHORT_SPECIAL_DAYS, {
      onError: (err: ApolloError) => setErrorMsg(err.message),
      onCompleted,
    });

  const onEditSpecialDays = async (days: Date[]) => {
    const specialDays = uniq(days.map((d) => normalizeToUtcDate(d).getTime()));

    const variables = {
      input: {
        id: entityId,
        studentNoShowDays: isNoShowDays ? specialDays : null,
        studentAssessmentDays: isNoShowDays ? null : specialDays,
      },
    };

    isCohortEntity
      ? await editCohortSpecialDays({ variables, refetchQueries })
      : await editEngagementSpecialDays({ variables, refetchQueries });
  };

  return (
    <Modal
      show={show}
      width="full"
      icon={<Modal.Icon icon="calendarDays" />}
      title={`Edit ${entityName} ${dayType} Dates`}
      onClose={noop}
      subtitle={getSpecialDaysModalSubtitle(entity, dayType)}
    >
      <SpecialDaysModalBody
        dayType={dayType}
        endDate={endDate}
        errorMsg={errorMsg}
        startDate={startDate}
        primarySpecialDays={primarySpecialDays}
        secondarySpecialDays={secondarySpecialDays}
        loading={isCohortEntity ? loadingCohort : loadingEngagement}
        onEdit={onEditSpecialDays}
        closeModal={closeModal}
      />
    </Modal>
  );
}
