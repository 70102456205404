import { gql } from "@apollo/client";
import { CohortStudentsTable_RowFragment } from "@generated/graphql";
import { getGradeLevelText } from "@utils/labels";
import { Routes } from "@utils/routes";
import clsx from "clsx";
import { Icon, Link, Table } from "components/shared";
import { getNormalizedShortReadableDate } from "helpers/dateText";
import { CellProps, Column } from "react-table";
import {
  getCohortStudentMembershipStatus,
  getCohortStudentMembershipTooltip,
} from "./helpers";
import { sortCohortStudents } from "./utils";

CohortStudentsTable.fragments = {
  cohortStudent: gql`
    fragment CohortStudentsTable_Row on CohortStudent {
      id
      createdAt
      removedAt
      startDate
      studentId
      removedBy {
        id
        fullName
      }
      removalNotes
      student {
        id
        grade
        fullName
        externalId
        classroomTeacherName
      }
    }
  `,
};

type CohortStudentTableStudent = CohortStudentsTable_RowFragment & {
  cannotRemove?: boolean;
};

export type CohortStudentTableRow = CohortStudentTableStudent & {
  isLateAddition: boolean;
  isRemoved: boolean;
};

type Props = {
  className?: string;
  isSimpleTable?: boolean;
  cohortStartDate: Date | null;
  cohortCreatedAtDate: Date | null;
  students: CohortStudentTableStudent[];
  removeStudent?: (student: CohortStudentsTable_RowFragment) => void;
};

export function CohortStudentsTable({
  students,
  className,
  cohortStartDate,
  cohortCreatedAtDate,
  isSimpleTable = false,
  removeStudent,
}: Props) {
  return (
    <Table
      data={students
        .map(
          (student) =>
            ({
              ...student,
              ...getCohortStudentMembershipStatus(
                student,
                cohortStartDate,
                cohortCreatedAtDate
              ),
            }) as CohortStudentTableRow
        )
        .sort(sortCohortStudents)}
      dataName="Cohort Students"
      className={clsx("min-h-0", className)}
      cellPadding="px-2 py-2"
      emptyStateSubtitle="Add students to populate this table."
      emptyStateIcon={<Icon icon="student" size={7} />}
      columns={getColumns(isSimpleTable, removeStudent)}
    />
  );
}

const getColumns = (
  isSimpleTable: boolean,
  removeStudent?: (student: CohortStudentsTable_RowFragment) => void
): Column<CohortStudentTableRow>[] => {
  const baseColumns: Column<CohortStudentTableRow>[] = [
    {
      Header: "Name",
      accessor: "studentId",
      Cell: ({ row: { original } }: CellProps<CohortStudentTableRow>) =>
        getCohortStudentMembershipTooltip(
          original,
          <span className="flex items-center gap-x-1 flex-wrap">
            {original.studentId ? (
              <Link
                route={Routes.student.details}
                routeProps={[original.studentId]}
                className={clsx(
                  original.isRemoved
                    ? "text-rose-700"
                    : original.isLateAddition && "text-emerald-700"
                )}
              >
                {original.student.fullName || "Unnamed Student"}
              </Link>
            ) : (
              original.student.fullName || "Unnamed Student"
            )}
            {original.isRemoved && (
              <div className="text-rose-700">(removed)</div>
            )}
            {original.isLateAddition && (
              <div className="text-emerald-700"> (late addition) </div>
            )}
            {(original.isRemoved || original.isLateAddition) && (
              <Icon icon="info" size={4} />
            )}
          </span>
        ),
    },
    {
      Header: () => (
        <div className="absolute top-0 left-0 w-full h-full flex flex-center">
          Grade
        </div>
      ),
      accessor: "student",
      width: 100,
      Cell: ({ row: { original } }: CellProps<CohortStudentTableRow>) => (
        <div className="flex w-full justify-center">
          {getGradeLevelText(original.student.grade)}
        </div>
      ),
    },
    {
      Header: "External ID",
      accessor: "id",
      minWidth: 100,
      Cell: ({ row: { original } }: CellProps<CohortStudentTableRow>) => (
        <div className="flex items-center justify-between gap-x-3">
          {original.student.externalId}
          {original.isRemoved === false &&
            original.cannotRemove !== true &&
            !!original.student && (
              <div
                className="flex flex-center cursor-pointer"
                onClick={() => removeStudent?.(original)}
              >
                <Icon icon="remove" size={5} color="text-rose-800/90" />
              </div>
            )}
        </div>
      ),
    },
  ];

  if (!isSimpleTable) {
    baseColumns.splice(1, 0, {
      Header: "Teacher Name",
      accessor: "removalNotes",
      Cell: ({ row: { original } }: CellProps<CohortStudentTableRow>) =>
        original.student.classroomTeacherName,
    });
    baseColumns.splice(1, 0, {
      Header: "Start Date",
      accessor: "startDate",
      Cell: ({ row: { original } }: CellProps<CohortStudentTableRow>) =>
        getNormalizedShortReadableDate(new Date(original.startDate)),
    });
  }

  return baseColumns as Column<CohortStudentTableRow>[];
};
