import { gql } from "@apollo/client";
import {
  CohortDetailsCard_CohortFragment,
  GradeLevel,
  Language,
} from "@generated/graphql";
import { getGradeLevelText, getLanguageText } from "@utils/labels";
import { Routes } from "@utils/routes";
import { EditCohortModal } from "components/cohorts/modals/AddEditModals";
import { Link } from "components/shared";
import {
  Card,
  getEditModalOpenHeader,
  HeaderColors,
} from "components/shared/Card";
import { CopyText } from "components/shared/TextHelpers/CopyText";
import { useState } from "react";

CohortDetailsCard.fragments = {
  cohort: gql`
    fragment CohortDetailsCard_Cohort on Cohort {
      id
      language
      hourlyTTRate
      instructionLevel
      publicReferenceId
      engagement {
        id
        name
        hourlyTTRate
        organization {
          id
          name
        }
      }
    }
  `,
};

type Props = {
  className?: string;
  cohort: CohortDetailsCard_CohortFragment;
};

export function CohortDetailsCard({ className, cohort }: Props) {
  const [showModal, setShowModal] = useState(false);
  const { engagement, instructionLevel, language } = cohort;
  const { organization, id: engId, name: engName } = engagement;
  const { id: orgId, name: orgName } = organization;

  return (
    <>
      <Card
        icon="details"
        className={className}
        headerOverlayColor={HeaderColors.Blue}
        header={getEditModalOpenHeader("Details", () => setShowModal(true))}
        rows={[
          [
            "Organization",
            <Link
              key="Organization"
              route={Routes.org.details}
              routeProps={[orgId]}
            >
              {orgName}
            </Link>,
          ],
          [
            "Engagement",
            <Link
              key={"Engagement"}
              route={Routes.engagement.details}
              routeProps={[engId]}
            >
              {engName}
            </Link>,
          ],
          ["Cohort ID", <CopyText key={0} text={cohort.id} />],
          [
            "Public Reference ID",
            <CopyText key={0} text={cohort.publicReferenceId} />,
          ],
          ["Language", getLanguageText(language ?? Language.English)],
          [
            "Instruction Level",
            getGradeLevelText(instructionLevel ?? GradeLevel.Unknown),
          ],
          [
            "TT Pay Rate",
            `$${(
              cohort.hourlyTTRate ?? engagement.hourlyTTRate
            ).toString()}/Hour`,
          ],
        ]}
      />

      <EditCohortModal
        show={showModal}
        cohortId={cohort.id}
        closeModal={() => setShowModal(false)}
      />
    </>
  );
}
